<template>
  <v-menu offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-card class="profile-view" elevation="5" v-bind="attrs" v-on="on">
        <v-avatar size="50">
          <img v-if="userImage" :src="userImage" alt="Image" />
          <img v-else src="../assets/user.jpg" alt="Image" />
        </v-avatar>
      </v-card>
    </template>

    <v-card class="profile-view__popup">
      <div class="profile-view__image">
        <v-avatar size="80">
          <img v-if="userImage" :src="userImage" alt="Image" />
          <img v-else src="../assets/user.jpg" alt="Image" />
        </v-avatar>
      </div>

      <v-spacer />
      <p id="display-name">{{ user.name || '' }}</p>
      <p id="display-name">{{ getUsername() }}</p>

      <v-spacer />
      <v-divider style="margin: 0" />

      <div class="profile-view__popup__actions">
        <v-btn
          elevation="0"
          outlined
          color="primary"
          @click="$router.push('/setting')"
          >Edit</v-btn
        >
        <v-spacer />
        <v-btn elevation="0" color="black" dark @click="logout">Logout</v-btn>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { getFullPath } from '../utils/local';

export default {
  name: 'ProfilePopup',

  data: () => ({
    userImage: '',
    user: ''
  }),

  mounted() {
    const token = localStorage.getItem('auth_token');
    if (token) {
      this.user = JSON.parse(localStorage.getItem('user'));
      if (this.user.image) {
        let imageURL = this.getFullPath(this.user.image);
        this.userImage = imageURL;
      }
    }
  },

  methods: {
    getFullPath,

    resolveLetter() {
      let i = '';
      if (this.user) {
        if (this.user.email) i += this.user.email[0];
      } else {
        i = '-';
      }
      return i.toUpperCase();
    },
    getUsername() {
      if (this.user) {
        let name = this.user.email;
        return name;
      } else {
        return '_';
      }
    },
    logout() {
      if (confirm('Are you sure you want to logout?')) {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('user');

        this.$router.push('/auth/sign-in');
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.profile-view
  width: 50px
  height: 50px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 25px !important

  &__letter
    font-size: 25px
    color: rgba(black, .7)

  &__image
    width: 80px
    height: 80px
    background: #f5c187
    margin: 0 auto
    border-radius: 40px !important
    display: flex
    justify-content: center
    align-items: center

  &__popup
    width: 290px
    height: 220px
    padding: 15px
    display: flex
    text-align: center
    flex-direction: column

    &__actions
      margin-top: 10px
      display: flex

#display-name
  margin: 0
  font-size: 16px
  font-weight: bold

#username
  margin: 0
</style>
