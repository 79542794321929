<template>
  <SimpleForm
    :is-edit="isEdit"
    :disabled="disabled"
    :onSubmit="submit"
    @done="$router.back()"
  >
    <v-row no-gutters class="d-flex span-2">
      <v-btn color="primary" class="mr-10" outlined @click="backRoute">
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <p class="form__title">
        {{
          isEdit ? 'Update Assessment Question' : 'Add New Assessment Question'
        }}
      </p>
    </v-row>

    <v-text-field
      class="span-2"
      label="Type"
      :value="
        question.assessmentType === 1
          ? 'Behavioral Intelligence'
          : question.assessmentType === 2
          ? 'Wheel Of Life'
          : 'Additional Assessment'
      "
      outlined
      disabled
    ></v-text-field>

    <v-text-field
      v-if="question.assessmentType === 3"
      v-model="question.name"
      :rules="[required('Name must be provided')]"
      class="span-2"
      label="Name"
      outlined
    />

    <v-text-field
      v-else
      v-model="question.question"
      :rules="[required('Question must be provided'), requiredV2()]"
      class="span-2"
      label="Question"
      outlined
    />

    <v-text-field
      v-if="question.assessmentType === 3"
      v-model="question.link"
      :rules="[required('Link must be provided')]"
      class="span-2"
      label="Link"
      outlined
    />

    <v-select
      v-if="question.assessmentType === 1"
      v-model="question.scoreType"
      :rules="[required('Score Type must be provided')]"
      class="span-2"
      label="Score Type"
      outlined
      :items="[
        { text: 'Stop', value: 1 },
        { text: 'Think', value: 2 },
        { text: 'Assess', value: 3 },
        { text: 'Respond', value: 4 },
        { text: 'Review', value: 5 },
        { text: 'General', value: 6 },
        { text: 'GlobalBIQ', value: 7 },
        { text: 'Validity', value: 8 }
      ]"
    />

    <v-select
      v-if="question.assessmentType === 2"
      v-model="question.scoreType"
      :rules="[required('Score Type must be provided')]"
      class="span-2"
      label="Score Type"
      outlined
      :items="[
        { text: 'Stop', value: 1 },
        { text: 'Think', value: 2 },
        { text: 'Assess', value: 3 },
        { text: 'Respond', value: 4 },
        { text: 'Review', value: 5 },
        { text: 'General', value: 6 },
        { text: 'GlobalBIQ', value: 7 },
        { text: 'Validity', value: 8 },
        { text: 'Family', value: 9 },
        { text: 'Emotional', value: 10 },
        { text: 'Physical', value: 11 },
        { text: 'Health', value: 12 },
        { text: 'Financial', value: 13 },
        { text: 'Social', value: 14 },
        { text: 'Intellect', value: 15 },
        { text: 'Career', value: 16 },
        { text: 'Spiritual', value: 17 },
        { text: 'Environment', value: 18 }
      ]"
    />

    <v-checkbox
      v-if="question.assessmentType !== 3"
      v-model="question.isScoreInReverseOrder"
      label="Is Score In Reverse Order"
      class="span-2 mt-n2"
    />

    <loading-dialog
      v-model="loading"
      message="Fetching Assessment Question Data"
    />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { AssessmentQuestionsService } from '@/services/assessment-questions-service';
import LoadingDialog from '../../components/LoadingDialog';
import { required, requiredV2, email } from '@/utils/validators';
import { getUser } from '../../utils/local';

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    service: new AssessmentQuestionsService(),
    user: getUser(),

    // only for edit
    disabled: false,
    question: {
      question: '',
      name: '',
      link: '',
      assessmentType: 1,
      scoreType: 1,
      isScoreInReverseOrder: false,
      businessAdminId: getUser().role === 0 ? null : getUser()._id
    }
  }),

  async mounted() {
    await this.loadQuestion();

    if (this.$route.query.tab == 0) {
      this.question.assessmentType = 1;
    } else if (this.$route.query.tab == 1) {
      this.question.assessmentType = 2;
    } else if (this.$route.query.tab == 2) {
      this.question.assessmentType = 3;
    } else {
      this.question.assessmentType = 1;
    }

    if (this.user.role === 1) {
      this.question.assessmentType = 3;
    }
  },

  methods: {
    required,
    requiredV2,
    email,

    backRoute() {
      let tab;
      if (this.question.assessmentType == 1) {
        tab = 0;
      } else if (this.question.assessmentType == 2) {
        tab = 1;
      } else if (this.question.assessmentType == 3) {
        tab = 2;
      }

      this.$router.push({
        path: '/assessment-questions',
        query: { tab: tab }
      });
    },

    async loadQuestion() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.question = await this.service.fetchOne(this.$route.query.id);
      this.loading = false;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Assessment Question');

        try {
          let data;
          if (this.question.assessmentType === 3) {
            data = {
              _id: this.question._id,
              name: this.question.name,
              link: this.question.link,
              assessmentType: this.question.assessmentType,
              businessAdminId: this.question.businessAdminId
            };
          } else {
            data = {
              _id: this.question._id,
              question: this.question.question,
              assessmentType: this.question.assessmentType,
              scoreType: this.question.scoreType,
              isScoreInReverseOrder: this.question.isScoreInReverseOrder,
              businessAdminId: this.question.businessAdminId
            };
          }
          this.service.update(data);
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while updating Assessment Question',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });
          return false;
        }
      } else {
        context.changeLoadingMessage('Creating Assessment Question');
        try {
          let data;
          if (this.question.assessmentType === 3) {
            data = {
              name: this.question.name,
              link: this.question.link,
              assessmentType: this.question.assessmentType,
              businessAdminId: this.question.businessAdminId
            };
          } else {
            data = {
              question: this.question.question,
              assessmentType: this.question.assessmentType,
              scoreType: this.question.scoreType,
              isScoreInReverseOrder: this.question.isScoreInReverseOrder,
              businessAdminId: this.question.businessAdminId
            };
          }
          await this.service.create(data);
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating Assessment Question',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });

          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
