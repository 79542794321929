import HomeMain from './HomeView.vue';

export const HomeRoutes = [
  {
    name: 'home',
    path: '/',
    component: HomeMain
  }
];

export const homeRouter = HomeRoutes.filter(function (x) {
  return x !== null;
});
