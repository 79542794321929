<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay
        v-if="dataLoading"
        :absolute="dataLoading"
        color="primary"
        opacity="0.55"
      >
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-row no-gutters class="d-flex align-center">
          <v-btn color="primary" outlined @click="backRoute">
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
          <v-card-title style="color: #020819"
            >Assessment Question Details</v-card-title
          >
        </v-row>

        <v-row no-gutters>
          <v-col
            cols="12"
            md="6"
            v-if="question.assessmentType === 'Additional Assessment'"
          >
            <v-card-text> <b>Name : </b>{{ question.name }} </v-card-text>
          </v-col>

          <v-col cols="12" md="6" v-else>
            <v-card-text>
              <b>Question : </b>{{ question.question }}
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text>
              <b>Type : </b>{{ question.assessmentType }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col
            cols="12"
            md="6"
            v-if="question.assessmentType !== 'Additional Assessment'"
          >
            <v-card-text>
              <b>Score Type : </b>{{ question.scoreType }}
            </v-card-text>
          </v-col>

          <v-col
            cols="12"
            md="6"
            v-if="question.assessmentType === 'Additional Assessment'"
          >
            <v-card-text> <b>Link : </b>{{ question.link }} </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text>
              <b>Created By : </b
              >{{ question.businessAdminId ? 'Business Admin' : 'Super Admin' }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card-text>
              <b>Created At : </b>{{ formatDate(question.createdAt) }}
            </v-card-text>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { AssessmentQuestionsService } from '@/services/assessment-questions-service';
import { getFullPath } from '../../utils/local';

export default {
  data() {
    return {
      dataLoading: true,
      question: {},
      service: new AssessmentQuestionsService()
    };
  },
  methods: {
    getFullPath,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm A');
    },

    backRoute() {
      let tab;
      if (this.question.assessmentType == 'Behavioral Intelligence') {
        tab = 0;
      } else if (this.question.assessmentType == 'Wheel Of Life') {
        tab = 1;
      } else if (this.question.assessmentType == 'Additional Assessment') {
        tab = 2;
      }

      this.$router.push({
        path: '/assessment-questions',
        query: { tab: tab }
      });
    },

    async loadQuestion() {
      try {
        this.dataLoading = true;
        this.question = await this.service.fetchOne(this.$route.query.id);

        // assessment type
        if (this.question.assessmentType == 1) {
          this.question.assessmentType = 'Behavioral Intelligence';
        } else if (this.question.assessmentType == 2) {
          this.question.assessmentType = 'Wheel Of Life';
        } else if (this.question.assessmentType == 3) {
          this.question.assessmentType = 'Additional Assessment';
        }

        // score type
        if (this.question.scoreType == 1) {
          this.question.scoreType = 'Stop';
        } else if (this.question.scoreType == 2) {
          this.question.scoreType = 'Think';
        } else if (this.question.scoreType == 3) {
          this.question.scoreType = 'Assess';
        } else if (this.question.scoreType == 4) {
          this.question.scoreType = 'Respond';
        } else if (this.question.scoreType == 5) {
          this.question.scoreType = 'Review';
        } else if (this.question.scoreType == 6) {
          this.question.scoreType = 'General';
        } else if (this.question.scoreType == 7) {
          this.question.scoreType = 'GlobalBIQ';
        } else if (this.question.scoreType == 8) {
          this.question.scoreType = 'Validity';
        }
      } catch (error) {
        console.log(error);
      }

      this.dataLoading = false;
    }
  },
  async mounted() {
    await this.loadQuestion();
  }
};
</script>
