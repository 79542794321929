<template>
  <v-card style="background-color: #424242">
    <v-tabs v-model="tab" dark color="primary">
      <v-tab> FAQ's </v-tab>
      <v-tab> Term & conditions </v-tab>
      <v-tab> Privacy & policy </v-tab>
    </v-tabs>

    <data-table
      :loader="loadData"
      :headers="getHeaders"
      title="Term Hub"
      :allow-add="user && user.role == 0 ? true : null"
      @done="$router.back()"
      @add-new="addNew"
      :view-handler="user && user.role == 0 ? view : null"
      :edit-handler="user && user.role == 0 ? edit : null"
      :delete-handler="user && user.role == 0 ? deleteQuestion : null"
      :key="tab"
    >
      <template #createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </data-table>
  </v-card>
</template>

<script>
import { TermHubsService } from '@/services/term-hub-service';
import DataTable from '../../components/DataTable';
import dayjs from 'dayjs';
import { getUser } from '@/utils/local';

export default {
  components: { DataTable },

  data: () => ({
    tab: 0,
    headers1: [
      {
        text: 'Question',
        value: 'question',
        sortable: true
      },
      {
        text: 'Answer',
        value: 'answer',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      }
    ],
    headers2: [
      {
        text: 'Detail',
        value: 'details',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      }
    ],
    loading: false,
    service: new TermHubsService(),
    user: getUser(),
    hubs: []
  }),

  computed: {
    getHeaders() {
      if (this.tab === 0) return this.headers1;
      return this.headers2;
    }
  },

  mounted() {
    if (this.$route.query.tab == 0) {
      this.tab = 0;
    } else if (this.$route.query.tab == 1) {
      this.tab = 1;
    } else if (this.$route.query.tab == 2) {
      this.tab = 2;
    } else {
      this.tab = 0;
    }
  },
  
  methods: {
    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY - hh:mm A');
    },

    addNew() {
      this.$router.push(`/term-hub?tab=${this.tab}`);
    },

    edit(item) {
      this.$router.push(`/term-hub?id=${item._id}&tab=${this.tab}`);
    },

    deleteQuestion(item) {
      this.service.delete(item._id);
    },

    view(item) {
      this.$router.push(`/term-hub-details?id=${item._id}`);
    },

    async loadData() {

      this.hubs = await this.service.fetchAllByType(
        this.tab ? this.tab + 1 : 1
      );

      console.log('this.hubs: ', this.hubs);
      return this.hubs;
    }
  }
};
</script>

<style scoped>
.column-header {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

.item-row {
  font-size: 0.875rem;
  height: 48px;
}

.border-bottom {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.flex-1 {
  flex: 1;
}

p {
  margin: 0;
}

/* .height-full {
  height: 80vh;
} */

.data-table__header {
  font-size: 25px;
  font-family: google-sans, sans-serif;
}

.btn-text {
  color: #ffffff;
}

.delete-btn {
  cursor: pointer;
}

.delete-row {
  text-decoration: line-through;
}

.newly_added {
  background-color: rgba(195, 253, 157, 0.4);
}

.dark-mode {
  background-color: #1e1e1e;
  color: white;
}
</style>
