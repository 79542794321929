<template>
  <SimpleForm
    :is-edit="isEdit"
    :disabled="disabled"
    :onSubmit="submit"
    @done="$router.push('/library-all?tab=1')"
  >
    <v-row no-gutters class="d-flex span-2">
      <v-btn
        color="primary"
        style="margin-right: 140px"
        outlined
        @click="$router.back()"
      >
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <p class="form__title">
        {{ isEdit ? 'Update Guidelines' : 'Add New Guidelines' }}
      </p>
    </v-row>

    <v-select
      v-model="resourceType"
      :items="resourceTypeOptions"
      :rules="[!isEdit ? required('ResourceType must be provided') : true]"
      :error="hasError"
      :error-messages="errorMessage"
      :disabled="isEdit"
      class="span-2"
      label="Resource Type"
      outlined
    >
    </v-select>

    <v-col cols="12" class="d-flex justify-space-between span-2">
      <p class="form__title">Guidelines Items</p>

      <v-btn v-if="!isEdit" color="primary" outlined @click="addGuideline">
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </v-col>

    <v-col
      v-for="(item, index) in guidelines"
      :key="index"
      cols="12"
      class="span-2"
    >
      <v-col cols="12" class="span-2 d-flex justify-end">
        <v-btn
          small
          color="red"
          outlined
          @click="
            !isEdit ? guidelines.splice(index, 1) : deleteGuideline(index)
          "
        >
          <v-icon small color="red"> mdi-delete </v-icon>
        </v-btn>
      </v-col>

      <v-text-field
        v-model="item.title"
        :rules="[required('Title must be provided')]"
        label="Title"
        outlined
        :disabled="isEdit ? true : false"
      ></v-text-field>

      <!-- for image -->
      <v-img
        v-if="
          !isEdit
            ? finalData.guidelines.items[index].thumbnail
            : guidelinesViewUrl[index].thumbnail
        "
        :src="
          !isEdit
            ? finalData.guidelines.items[index].thumbnail
            : guidelinesViewUrl[index].thumbnail
        "
        :height="300"
        contain
        class="span-2 mb-8"
      ></v-img>

      <v-file-input
        v-model="item.thumbnail"
        :rules="[!isEdit ? required('Thumbnail must be provided') : true]"
        class="span-2"
        :label="isEdit ? 'Change Thumbnail' : 'Thumbnail'"
        outlined
        accept="image/png, image/jpeg, image/jpg, image/gif"
        :disabled="isEdit ? true : false"
        @click:clear="() => (finalData.guidelines.items[index].thumbnail = '')"
      ></v-file-input>

      <!-- for pdf -->
      <v-file-input
        v-if="resourceType === 'PDF'"
        v-model="item.link"
        :rules="[!isEdit ? required('PDF must be provided') : true]"
        class="span-2"
        :label="isEdit ? 'Change PDF' : 'PDF'"
        outlined
        accept="application/pdf, .pdf"
        :disabled="isEdit ? true : false"
        @click:clear="() => (finalData.guidelines.item[index].link = '')"
      ></v-file-input>

      <iframe
        v-if="resourceType !== 'IMAGE' ? (
          !isEdit
            ? finalData.guidelines.items[index].link
            : guidelinesViewUrl[index].link ) : false
        "
        :src="
          !isEdit
            ? finalData.guidelines.items[index].link
            : guidelinesViewUrl[index].link
        "
        height="300px"
        width="100%"
        frameborder="0"
        class="span-2 mb-8"
      ></iframe>

      <v-file-input
        v-if="resourceType === 'VIDEO'"
        v-model="item.link"
        :rules="[!isEdit ? required('Video must be provided') : true]"
        class="span-2"
        :label="isEdit ? 'Change Video' : 'Video'"
        outlined
        accept="Video/*"
        :disabled="isEdit ? true : false"
        @click:clear="() => (finalData.guidelines.item[index].link = '')"
      ></v-file-input>
    </v-col>

    <v-col cols="12" class="d-flex justify-space-between span-2" v-if="isEdit">
      <p class="form__title">New Items</p>

      <v-btn color="primary" outlined @click="addNewGuideline">
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </v-col>

    <v-col
      v-for="(newItem, index) in newGuidelines"
      :key="index + 100"
      cols="12"
      class="span-2"
    >
      <v-col cols="12" class="span-2 d-flex justify-end">
        <v-btn
          small
          color="red"
          outlined
          @click="newGuidelines.splice(index, 1)"
        >
          <v-icon small color="red"> mdi-delete </v-icon>
        </v-btn>
      </v-col>

      <v-text-field
        v-model="newItem.title"
        :rules="[required('Title must be provided')]"
        label="Title"
        outlined
      ></v-text-field>

      <!-- for image -->
      <v-img
        v-if="newGuidelinesViewUrl[index].thumbnail"
        :src="newGuidelinesViewUrl[index].thumbnail"
        :height="300"
        contain
        class="span-2 mb-8"
      ></v-img>

      <v-file-input
        v-model="newItem.thumbnail"
        :rules="[!isEdit ? required('Thumbnail must be provided') : true]"
        class="span-2"
        :label="isEdit ? 'Change Thumbnail' : 'Thumbnail'"
        outlined
        accept="image/png, image/jpeg, image/jpg, image/gif"
        @click:clear="() => (newItem.thumbnail = '')"
      ></v-file-input>

      <!-- for pdf -->
      <v-file-input
        v-if="resourceType === 'PDF'"
        v-model="newItem.link"
        :rules="[!isEdit ? required('PDF must be provided') : true]"
        class="span-2"
        :label="isEdit ? 'Change PDF' : 'PDF'"
        outlined
        accept="application/pdf, .pdf"
        @click:clear="() => (newItem.thumbnail = '')"
      ></v-file-input>

      <iframe
        v-if="resourceType !== 'IMAGE' ? (newGuidelinesViewUrl[index].link) : false"
        :src="newGuidelinesViewUrl[index].link"
        height="300px"
        width="100%"
        frameborder="0"
        class="span-2 mb-8"
      ></iframe>

      <v-file-input
        v-if="resourceType === 'VIDEO'"
        v-model="newItem.link"
        :rules="[!isEdit ? required('Video must be provided') : true]"
        class="span-2"
        :label="isEdit ? 'Change Video' : 'Video'"
        outlined
        accept="Video/*"
        :disabled="isEdit ? true : false"
        @click:clear="() => (newItem.link = '')"
      ></v-file-input>
    </v-col>

    <loading-dialog v-model="loading" message="Please Wait..." />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { LibraryService } from '@/services/library-service';
import LoadingDialog from '../../components/LoadingDialog';
import { required, httpLink } from '../../utils/validators';
import { uploadFile, getFullPath, deleteFile } from '../../utils/local';
import { getUser } from '../../utils/local';

export default {
  name: 'GuidelinesForm',
  components: { LoadingDialog, SimpleForm },

  data() {
    return {
      isEdit: false,
      loading: false,
      service: new LibraryService(),
      user: getUser(),
      hasError: false,
      errorMessage: '',
      resourceTypeOptions: [
        { text: 'Pdfs', value: 'PDF' },
        { text: 'Videos', value: 'VIDEO' },
        { text: 'Images', value: 'IMAGE' }
      ],
      resourceType: '',
      disabled: false,
      thumbnail: null,
      guidelines: [
        // {
        //   title: '',
        //   thumbnail: null,
        //   link: null,
        // },
      ],
      newGuidelines: [],
      newGuidelinesViewUrl: [],
      guidelinesViewUrl: [
        {
          thumbnail: null,
          link: null
        }
      ],
      oldGuidelines: [],
      // Avoid referencing `this` directly in `data` initialization
      finalData: {
        itemType: 'GUIDELINES',
        guidelines: {
          resourceType: '',
          items: [
            {
              title: '',
              thumbnail: '',
              link: ''
            }
          ]
        },
        createdBy: ''
      },
      deleteIndex: []
    };
  },

  mounted() {
    this.finalData.createdBy = this.user._id;
    this.loadData();
  },

  watch: {
    thumbnail: {
      handler: function (val) {
        if (!val) return;
        this.finalData.guidelines.thumbnail = URL.createObjectURL(val);
      }
    },

    guidelines: {
      handler: function (val) {
        if (!val) return;

        val.forEach((item, index) => {
          if (!item.thumbnail) return;
          this.finalData.guidelines.items[index].thumbnail =
            URL.createObjectURL(item.thumbnail);

          if (!item.link) return;
          this.finalData.guidelines.items[index].link = URL.createObjectURL(
            item.link
          );
        });
      },
      deep: true
    },

    newGuidelines: {
      handler: function (val) {
        if (!val) return;

        val.forEach((item, index) => {
          if (!item.thumbnail) return;
          this.newGuidelinesViewUrl[index].thumbnail = URL.createObjectURL(
            item.thumbnail
          );

          if (!item.link) return;
          this.newGuidelinesViewUrl[index].link = URL.createObjectURL(
            item.link
          );
        });
      },
      deep: true
    },

    resourceType: {
      handler: function (val) {
        if (!this.isEdit) {
          console.log('val: ', val);
          this.guidelines = [...[]];
          this.finalData.guidelines.items = [...[]];
          this.guidelinesViewUrl = [...[]];
        }
      }
    }
  },

  methods: {
    required,
    httpLink,
    uploadFile,
    getFullPath,
    deleteFile,
    scrollToBottom() {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    },

    async deleteGuideline(index) {
      this.loading = true;

      if (this.oldGuidelines[index].thumbnail) {
        await this.deleteFile(this.oldGuidelines[index].thumbnail);
      }

      if (this.oldGuidelines[index].link) {
        await this.deleteFile(this.oldGuidelines[index].link);
      }

      this.finalData.guidelines.items.splice(index, 1);
      this.guidelines.splice(index, 1);
      this.guidelinesViewUrl.splice(index, 1);

      if(this.isEdit) {
        let newGuidelinesArr = [];

          if (this.newGuidelines.length > 0) {
            let newGuidelinesCLone = [...this.newGuidelines];

            for (let i = 0; i < newGuidelinesCLone.length; i++) {
              let guide = newGuidelinesCLone[i];
              let obj = {};
              obj['title'] = guide.title;
              if (guide.thumbnail) {
                const itemThumbnailResponse = await this.uploadFile(
                  guide.thumbnail
                );
                obj['thumbnail'] = itemThumbnailResponse.data.name;
              }

              if (guide.link) {
                const itemLinkResponse = await this.uploadFile(guide.link);
                obj['link'] = itemLinkResponse.data.name;
              }

              newGuidelinesArr.push(obj);
            }
          }

          const data = {};
          data['_id'] = this.finalData._id;
          data['cratedBy'] = this.finalData.createdBy;
          data['itemType'] = this.finalData.itemType;
          let guidelines = {};
          guidelines['resourceType'] = this.finalData.guidelines.resourceType;

          let items = [];
          this.finalData.guidelines.items.forEach((item) => {
            if (item) items.push({ ...item });
          });

          newGuidelinesArr.forEach((item) => {
            if (item) items.push(item);
          });

          guidelines['items'] = items;
          data['guidelines'] = guidelines;

          console.log('data:', data);

          await this.service.update(data);
      }

      this.loading = false;
    },

    addGuideline() {
      if (!this.resourceType) {
        this.hasError = true;
        this.errorMessage = 'Please select a valid option';
        return;
      } else {
        this.hasError = false;
        this.errorMessage = '';
      }

      this.guidelines.push(
        this.resourceType === 'IMAGE'
          ? {
              title: '',
              thumbnail: null
            }
          : {
              title: '',
              thumbnail: null,
              link: null
            }
      );

      this.finalData.guidelines.items.push(
        this.resourceType === 'IMAGE'
          ? {
              title: '',
              thumbnail: ''
            }
          : {
              title: '',
              thumbnail: '',
              link: ''
            }
      );

      this.guidelinesViewUrl.push(
        this.resourceType === 'IMAGE'
          ? {
              thumbnail: null
            }
          : {
              thumbnail: null,
              link: null
            }
      );

      this.scrollToBottom();
    },

    addNewGuideline() {
      this.newGuidelines.push(
        this.resourceType === 'IMAGE'
          ? {
              title: '',
              thumbnail: null
            }
          : {
              title: '',
              thumbnail: null,
              link: null
            }
      );

      this.newGuidelinesViewUrl.push(
        this.resourceType === 'IMAGE'
          ? {
              thumbnail: null
            }
          : {
              thumbnail: null,
              link: null
            }
      );

      this.scrollToBottom();
    },

    async loadData() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.finalData = await this.service.fetchOne(this.$route.query.id);
      this.resourceType = this.finalData.guidelines.resourceType;
      this.guidelines = this.finalData.guidelines.items.map((item) => ({
        title: item.title
      }));

      this.guidelinesViewUrl = this.finalData.guidelines.items.map((item) => ({
        thumbnail: this.getFullPath(item.thumbnail),
        link: this.getFullPath(item.link)
      }));

      this.oldGuidelines = this.finalData.guidelines.items;

      this.loading = false;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating guidelines');

        try {
          let newGuidelinesArr = [];

          if (this.newGuidelines.length > 0) {
            let newGuidelinesCLone = [...this.newGuidelines];

            for (let i = 0; i < newGuidelinesCLone.length; i++) {
              let guide = newGuidelinesCLone[i];
              let obj = {};
              obj['title'] = guide.title;
              if (guide.thumbnail) {
                const itemThumbnailResponse = await this.uploadFile(
                  guide.thumbnail
                );
                obj['thumbnail'] = itemThumbnailResponse.data.name;
              }

              if (guide.link) {
                const itemLinkResponse = await this.uploadFile(guide.link);
                obj['link'] = itemLinkResponse.data.name;
              }

              newGuidelinesArr.push(obj);
            }
          }

          const data = {};
          data['_id'] = this.finalData._id;
          data['cratedBy'] = this.finalData.createdBy;
          data['itemType'] = this.finalData.itemType;
          let guidelines = {};
          guidelines['resourceType'] = this.finalData.guidelines.resourceType;

          let items = [];
          this.finalData.guidelines.items.forEach((item) => {
            if (item) items.push({ ...item });
          });

          newGuidelinesArr.forEach((item) => {
            if (item) items.push(item);
          });

          guidelines['items'] = items;
          data['guidelines'] = guidelines;

          console.log('data:', data);

          await this.service.update(data);
          this.$toast.success('Item updated successfully');
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while updating Audio',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });
          return false;
        }
      } else {
        context.changeLoadingMessage('Creating Guidelines');
        try {
          if (this.guidelines.length > 0 && this.guidelines[0].title !== '') {
            const guidelines = await Promise.all(
              this.guidelines.map(async (item) => {
                const itemThumbnailResponse = await this.uploadFile(
                  item.thumbnail
                );

                if (item.link) {
                  const itemLinkResponse = await this.uploadFile(item.link);
                  return {
                    title: item.title,
                    thumbnail: itemThumbnailResponse.data.name,
                    link: itemLinkResponse.data.name
                  };
                }

                return {
                  title: item.title,
                  thumbnail: itemThumbnailResponse.data.name
                };
              })
            );
            this.finalData.guidelines.items = guidelines;
          } else {
            this.finalData.guidelines.items = [];
          }
          this.finalData.guidelines.resourceType = this.resourceType;

          await this.service.create(this.finalData);
          this.$toast.success('Guideline created successfully');
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating Audio',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });

          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
