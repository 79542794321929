<template>
  <data-table
    :loader="loadData"
    :headers="headers"
    title="Users"
    @done="$router.back()"
    :view-handler="user && user.role == 0 ? view : null"
  >
    <template #isPremium="{ item }">
      <v-icon :color="item.isPremium === true ? 'green' : 'red'">
        {{ item.isPremium === true ? 'mdi-check' : 'mdi-close' }}
      </v-icon>
    </template>

    <template #createdAt="{ item }">
      {{ formatdate(item.createdAt) }}
    </template>
  </data-table>
</template>

<script>
import { UsersService } from '@/services/users-service';
import DataTable from '../../components/DataTable';
import { getUserScopes } from '@/utils/local';
import { getUser } from '@/utils/local';
import dayjs from 'dayjs';

export default {
  components: { DataTable },

  data: () => ({
    user: getUser(),
    service: new UsersService(),
    userScopes: getUserScopes(),

    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      {
        text: 'Email',
        value: 'email',
        sortable: true
      },
      {
        text: 'isPremium',
        value: 'isPremium',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      }
    ]
  }),

  methods: {
    formatdate(date) {
      return dayjs(date).format('DD MMM YYYY - hh:mm A');
    },

    view(item) {
      this.$router.push(`/user-details?id=${item._id}`);
    },

    async loadData() {
      return await this.service.fetchAllUsers();
    }
  }
};
</script>
