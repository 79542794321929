import AdminsView from './AdminsView';
import AdminForm from './AdminForm.vue';
import { getUser } from '../../utils/local';

export const businessAdminsRoutes = [
  getUser()?.role === 0
    ? {
        name: 'BusinessAdmins',
        path: '/business-admins',
        component: AdminsView
      }
    : null,

  getUser()?.role === 0
    ? {
        name: 'NewBusinessAdmin',
        path: '/business-admin',
        component: AdminForm
      }
    : null
];

export const businessAdminsRouter = businessAdminsRoutes.filter(function (x) {
  return x !== null;
});
