import axios from 'axios';

export class AssessmentQuestionsService {
  async fetchAll() {
    return (await axios.get('/assessment-questions')).data;
  }

  async fetchAllByUserId(userId) {
    return (
      await axios.get(
        '/assessment-questions?assessmentType=3&businessAdminId=' + userId
      )
    ).data;
  }

  async fetchOne(id) {
    return (await axios.get('/assessment-questions/' + id)).data;
  }

  async create(data) {
    return (await axios.post('/assessment-questions', data)).data;
  }

  async update(data) {
    return (await axios.patch('/assessment-questions/' + data._id, data)).data;
  }

  async updateOrder(data) {
    return (await axios.patch('/assessment-questions/update-order', data)).data;
  }

  async delete(id) {
    return (await axios.delete('/assessment-questions/' + id)).data;
  }
}
