import axios from 'axios';

export function getUserScopes() {
  const user = localStorage.getItem('user');
  const scopes = JSON.parse(user)?.scopes;
  return scopes ? scopes : null;
}

export function getUser() {
  const user = JSON.parse(localStorage.getItem('user'));
  return user;
}

export function uploadFile(file) {
  const formData = new FormData();
  formData.append('image', file);
  return this.$axios.post('save-image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function getFullPath(_file) {
  return axios.defaults.baseURL + 'uploads/' + _file;
}

export function deleteFile(_file) {
  return axios.post('delete-image/' + _file);
}
