import SettingForm from './SettingForm.vue';

export const SettingRoutes = [
  {
    name: 'setting',
    path: '/setting',
    component: SettingForm
  }
];

export const settingRouter = SettingRoutes.filter(function (x) {
  return x !== null;
});
