<template>
  <v-card :class="[hasElevation ? 'elevation-2' : 'elevation-0']">
    <slot name="tabs" />
    <v-card-title
      :class="`d-flex ${
        $vuetify.breakpoint.width > 800 ? 'flex-row' : 'flex-column'
      } justify-start align-start`"
      :style="$vuetify.breakpoint.width > 800 ? '' : 'gap: 10px'"
      style="background-color: #363636; color: white"
    >
      <span class="data-table__header">{{ title }}</span>
      <v-spacer v-if="$vuetify.breakpoint.width > 800" />

      <div
        style="margin-right: 20px"
        :style="[
          $vuetify.breakpoint.width < 800
            ? { width: 100 + '%' }
            : { width: 400 + 'px' }
        ]"
      >
        <v-text-field
          v-if="allowSearch"
          v-model="search"
          label="Search anything"
          outlined
          dense
          hide-details
          clearable
          append-icon="mdi-magnify"
          :dark="true"
        />
      </div>
      <slot
        name="primary-action"
        :class="[$vuetify.breakpoint.width > 800 ? 'my-2' : '']"
      />

      <v-btn
        :style="[$vuetify.breakpoint.width < 800 ? { width: 100 + '%' } : '']"
        :class="[$vuetify.breakpoint.width > 800 ? 'ml-2' : '']"
        v-if="allowAdd"
        outlined
        dark
        elevation="4"
        @click="$emit('add-new')"
      >
        <v-icon class="v-btn__pre-icon" small>mdi-plus</v-icon>&nbsp; Add New
      </v-btn>

      <v-btn
        ref="refresh"
        :style="[$vuetify.breakpoint.width < 800 ? { width: 100 + '%' } : '']"
        :class="[$vuetify.breakpoint.width > 800 ? 'ml-2' : '']"
        outlined
        dark
        elevation="4"
        id="refresh"
        class="refresh"
        @click="loadData"
        v-if="allowRefresh"
      >
        <v-icon class="v-btn__pre-icon" small>mdi-refresh</v-icon>&nbsp; Refresh
      </v-btn>

      <v-btn
        v-if="allowFilters"
        :class="[$vuetify.breakpoint.width > 800 ? 'my-2' : '']"
        icon
        style="margin-left: 10px"
      >
        <v-icon @click="$emit('filter')">mdi-filter</v-icon>
      </v-btn>
    </v-card-title>

    <v-data-table
      :loading="loading"
      :items="items"
      :headers="headersValue"
      :search="search"
      :footer-props="{
        itemsPerPageOptions: [10, 20, 50, 100],
        itemsPerPage: 25
      }"
      height="calc(100vh - 270px)"
      mobile-breakpoint="800"
      fixed-header
      :items-per-page="20"
      :dark="true"
    >
      <template v-slot:item="{ item }">
        <tr v-if="$vuetify.breakpoint.width > 800">
          <td
            v-for="(elem, key) of headers"
            :key="key"
            :class="`text-${elem.align === 'right' ? 'end' : 'start'}`"
            :style="`max-width: calc(100% / ${headersValue.length})`"
            style="border: none !important"
            class="py-6"
          >
            <slot :name="elem.value" :item="item">{{ item[elem.value] }}</slot>
          </td>
          <td
            v-if="viewHandler || editHandler || deleteHandler || disableHandler"
            :style="`max-width: calc(100% / ${headersValue.length})`"
            style="border: none !important"
          >
            <div
              class="d-flex justify-end align-center flex-wrap"
              style="gap: 8px"
            >
              <slot name="extra-actions" :item="item" />
              <v-icon
                dark
                v-if="viewHandler"
                class="text--white"
                small
                @click="viewHandler(item)"
                color="primary"
              >
                mdi-eye
              </v-icon>
              <v-icon
                dark
                v-if="editHandler"
                small
                @click="editHandler(item)"
                color="green"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                dark
                v-if="deleteHandler"
                small
                @click="onDelete(item)"
                color="red"
              >
                mdi-delete
              </v-icon>
              <v-icon
                dark
                v-if="disableHandler"
                small
                @click="onDisable(item)"
                :color="item.status ? 'red' : 'green'"
              >
                {{ item.status ? 'mdi-close' : 'mdi-check' }}
              </v-icon>
            </div>
          </td>
        </tr>
        <tr v-else class="v-data-table__mobile-table-row">
          <td
            v-for="(elem, key) of headers"
            :key="key"
            :class="`v-data-table__mobile-row text-${
              elem.align === 'right' ? 'end' : 'start'
            }`"
          >
            <div class="v-data-table__mobile-row__header">{{ elem.text }}</div>
            <div class="v-data-table__mobile-row__cell">
              <slot :name="elem.value" :item="item">{{
                item[elem.value]
              }}</slot>
            </div>
          </td>
          <td
            v-if="viewHandler || editHandler || deleteHandler || disableHandler"
            class="v-data-table__mobile-row text-end"
            style="text-align: right"
          >
            <div class="v-data-table__mobile-row__header">Action</div>
            <div class="v-data-table__mobile-row__cell d-flex flex-column">
              <slot name="extra-actions" :item="item" />

              <v-icon
                dark
                class="my-1"
                v-if="viewHandler"
                @click="viewHandler(item)"
                color="primary"
              >
                mdi-eye
              </v-icon>

              <v-icon
                dark
                class="my-1"
                v-if="editHandler"
                small
                color="green"
                @click="editHandler(item)"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                dark
                class="my-1"
                v-if="deleteHandler"
                small
                color="red"
                @click="onDelete(item)"
              >
                mdi-delete
              </v-icon>

              <v-icon
                dark
                class="my-1"
                v-if="disableHandler"
                small
                :color="item.status ? 'red' : 'green'"
                @click="onDisable(item)"
              >
                {{ item.status ? 'mdi-close' : 'mdi-check' }}
              </v-icon>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>

    <error-dialog v-if="!hideDialog" v-model="error" :error="errorValue" />
  </v-card>
</template>

<script>
import ErrorDialog from './ErrorDialog';

export default {
  name: 'DataTable',
  components: { ErrorDialog },
  props: {
    allowRefresh: {
      type: Boolean,
      default: true
    },
    allowSearch: {
      type: Boolean,
      default: true
    },
    allowAdd: {
      type: Boolean,
      default: false
    },

    allowFilters: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: null
    },

    loader: {
      type: Function,
      required: true
    },

    headers: {
      type: Array,
      required: true
    },

    editHandler: {
      type: Function,
      default: null
    },

    viewHandler: {
      type: Function,
      default: null
    },

    deleteHandler: {
      type: Function,
      default: null
    },

    disableHandler: {
      type: Function,
      default: null
    },

    hasElevation: {
      type: Boolean,
      default: true
    },
    hideDialog: {
      type: Boolean,
      default: false
    }
  },
  emits: ['add-new'],

  mounted() {
    this.headersValue = [...this.headers];
    if (
      this.editHandler ||
      this.deleteHandler ||
      this.viewHandler ||
      this.disableHandler
    ) {
      this.headersValue.push({
        text: 'Actions',
        align: 'right',
        search: false,
        sortable: false
      });
    }

    this.loadData();
  },

  data: () => ({
    search: '',
    items: [],
    error: false,
    loading: false,
    errorValue: {},
    headersValue: []
  }),

  methods: {
    async loadData() {
      this.loading = true;
      try {
        this.items = await this.loader();
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.errorValue = {
          title: 'Error while loading data',
          description:
            e?.response?.data?.error ??
            'Some error occured, Please try again later'
        };
        this.error = true;
      }
    },

    async onDelete(item) {
      if (item && item.accountType == 'SUPER ADMIN') {
        this.$toast.error('You cannot delete super admin');
        return;
      }
      if (confirm('This Item will be deleted')) {
        try {
          this.loading = true;
          await this.deleteHandler(item);
          this.loading = false;
          this.items.splice(this.items.indexOf(item), 1);
        } catch (e) {
          window.console.log(e);
        }
      }
    },

    async onDisable(item) {
      let confirmMessage = item.status
        ? 'All the business users related to this are disabled'
        : 'All the business users related to this are enabled';

      if (
        item.role == 1
          ? confirm(confirmMessage)
          : item.status
          ? confirm('Are you sure you want to disable this user?')
          : confirm('Are you sure you want to enable this user?')
      ) {
        try {
          this.loading = true;
          await this.disableHandler(item);
          this.loading = false;
        } catch (e) {
          window.console.log(e);
        }
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.data-table

  &__header
    font-size: 25px
</style>
