import TermHubView from './TermHubView';
import TermHubShow from './TermHubShow';
import TermHubForm from './TermHubForm.vue';
import { getUser } from '../../utils/local';

export const termHubsRoutes = [
  getUser()?.role === 0
    ? {
        name: 'termHubs',
        path: '/term-hubs',
        component: TermHubView
      }
    : null,
  getUser()?.role === 0
    ? {
        name: 'NewTermHub',
        path: '/term-hub',
        component: TermHubForm
      }
    : null,
  getUser()?.role === 0
    ? {
        name: 'termHubDetails',
        path: '/term-hub-details',
        component: TermHubShow
      }
    : null
];

export const termHubsRouter = termHubsRoutes.filter(function (x) {
  return x !== null;
});
