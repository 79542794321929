<template>
  <SimpleForm
    :is-edit="isEdit"
    :disabled="disabled"
    :onSubmit="submit"
    @done="$router.push('/library-all?tab=2')"
  >
    <v-row no-gutters class="d-flex span-2">
      <v-btn
        color="primary"
        style="margin-right: 140px"
        outlined
        @click="$router.back()"
      >
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <p class="form__title">
        {{ isEdit ? 'Update Link' : 'Add New Link' }}
      </p>
    </v-row>

    <v-text-field
      v-model="data.links.title"
      :rules="[required('Title must be provided')]"
      label="Title"
      outlined
      class="span-2"
    ></v-text-field>

    <v-text-field
      v-model="data.links.link"
      :rules="[
        required('Link must be provided'),
        httpLink('Link must be a valid URL')
      ]"
      label="Link"
      outlined
      class="span-2"
    ></v-text-field>

    <loading-dialog v-model="loading" message="Fetching Link Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { LibraryService } from '@/services/library-service';
import LoadingDialog from '../../components/LoadingDialog';
import { required, httpLink } from '../../utils/validators';
import { uploadFile, getFullPath } from '../../utils/local';
import { getUser } from '../../utils/local';

export default {
  name: 'LinkForm',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    user: getUser(),
    service: new LibraryService(),

    // only for edit
    disabled: false,

    thumbnail: null,

    thumbnailViewUrl: null,

    oldThumbnail: null,

    data: {
      itemType: 'LINK',
      links: {
        title: '',
        thumbnail: '',
        link: ''
      },
      createdBy: ''
    }
  }),

  mounted() {
    this.data.createdBy = this.user._id;
    this.loadData();
  },

  watch: {
    thumbnail: {
      handler: function (val) {
        if (!val) return;
        this.data.links.thumbnail = URL.createObjectURL(val);
      }
    }
  },

  methods: {
    required,
    httpLink,
    uploadFile,
    getFullPath,

    async loadData() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.data = await this.service.fetchOne(this.$route.query.id);

      this.thumbnailViewUrl = this.getFullPath(this.data.links.thumbnail);

      this.oldThumbnail = this.data.links.thumbnail;

      this.loading = false;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Link');

        try {
          if (this.thumbnail) {
            const imageResponse = await this.uploadFile(this.thumbnail);
            this.data.links.thumbnail = imageResponse.data.name;
          }

          await this.service.update(this.data);

          this.$toast.success('Link updated successfully');
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while updating Link',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });
          return false;
        }
      } else {
        context.changeLoadingMessage('Creating Link');
        try {
          if (this.thumbnail) {
            const imageResponse = await this.uploadFile(this.thumbnail);
            this.data.links.thumbnail = imageResponse.data.name;
          }

          await this.service.create(this.data);
          this.$toast.success('Link created successfully');
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating Link',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });

          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
