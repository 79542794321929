<template>
  <SimpleForm
    :is-edit="isEdit"
    :disabled="disabled"
    :onSubmit="submit"
    @done="$router.back()"
  >
    <v-row no-gutters class="d-flex span-2">
      <v-btn color="primary" class="mr-16" outlined @click="$router.back()">
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <p class="form__title">
        {{ isEdit ? 'Update Super Admin' : 'Add New Super Admin' }}
      </p>
    </v-row>

    <v-text-field
      v-model="admin.name"
      :rules="[required('Name must be provided'), requiredV2()]"
      class="span-2"
      label="Name"
      outlined
    ></v-text-field>

    <v-text-field
      v-model="admin.email"
      :rules="[
        required('Email must be provided'),
        email('Email must be valid'),
        requiredV2()
      ]"
      class="span-2"
      label="Email"
      outlined
    />

    <v-text-field
      v-if="!isEdit"
      v-model="admin.password"
      label="Password"
      outlined
      :type="showPassword ? 'text' : 'password'"
    />

    <v-text-field
      v-if="!isEdit"
      v-model="admin.passwordConfirm"
      :rules="[(v) => v === admin.password || 'Passwords does not match']"
      label="Confirm Password"
      outlined
      :type="showPassword ? 'text' : 'password'"
    />

    <v-checkbox
      v-if="!isEdit"
      class="mt-n5"
      v-model="showPassword"
      :label="`Show password`"
    ></v-checkbox>

    <v-text-field
      v-if="isEdit && loggedInUserId === admin._id"
      v-model="password.old"
      class="span-2"
      label="Old Password(Optional)"
      outlined
    ></v-text-field>

    <v-text-field
      v-if="isEdit && loggedInUserId === admin._id"
      v-model="password.new"
      class="span-2"
      label="New Password(Optional)"
      outlined
    ></v-text-field>

    <loading-dialog v-model="loading" message="Fetching Admin Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { AdminsService } from '../../services/admin-service';
import LoadingDialog from '../../components/LoadingDialog';
import { required, requiredV2, email } from '../../utils/validators';

export default {
  name: 'AdminForm',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    admin_service: new AdminsService(),
    showPassword: '',

    // only for edit
    showImageError: false,
    disabled: false,
    loggedInUserId: JSON.parse(localStorage.getItem('user'))._id,
    admin: {
      role: 0,
      name: '',
      email: '',
      password: '',
      passwordConfirm: ''
    },
    password: {
      old: '',
      new: ''
    }
  }),

  mounted() {
    this.loadAdmin();
  },

  methods: {
    required,
    requiredV2,
    email,

    async loadAdmin() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.admin = await this.admin_service.fetchOne(this.$route.query.id);
      this.loading = false;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Super Admin');

        try {
          if (!this.password.old) {
            try {
              await this.admin_service.update(this.admin);
              this.$toast.success('Super Admin updated successfully');
              return true;
            } catch (e) {
              context.reportError({
                title: 'Error while updating super Admin',
                description: e.response
                  ? e.response.data.message
                  : 'Something went wrong!'
              });
              return false;
            }
          } else {
            try {
              if (!this.password.new) {
                context.reportError({
                  title: 'Error while updating super Admin',
                  description: 'New password is required'
                });
                return false;
              }
              await this.admin_service
                .changePassword({
                  person_id: this.admin._id,
                  oldPassword: this.password.old,
                  newPassword: this.password.new
                })
                .then(() => {
                  this.admin_service.update(this.admin);
                  this.$toast.success('Super Admin updated successfully');
                });
              return true;
            } catch (e) {
              context.reportError({
                title: 'Error while updating super Admin',
                description: e.response
                  ? e.response.data.message
                  : 'Something went wrong!'
              });
              return false;
            }
          }
        } catch (e) {
          context.reportError({
            title: 'Error while updating super Admin',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });
          return false;
        }
      } else {
        context.changeLoadingMessage('Creating Super Admin');
        try {
          await this.admin_service.create(this.admin);
          this.$toast.success('Super Admin created successfully');
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating super Admin',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });

          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
