<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay
        v-if="dataLoading"
        :absolute="dataLoading"
        color="primary"
        opacity="0.55"
      >
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-row no-gutters class="d-flex align-center">
          <v-btn color="primary" outlined @click="backRoute">
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
          <v-card-title style="color: #020819"
            >{{ getTitle }} Details</v-card-title
          >
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card-text>
              <b>Created At : </b>{{ formatDate(hub.createdAt) }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6" v-if="hub.question">
            <v-card-text> <b>Question : </b>{{ hub.question }} </v-card-text>
          </v-col>

          <v-col cols="12" md="6" v-if="hub.answer">
            <v-card-text> <b>Answer : </b>{{ hub.answer }} </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters v-if="hub.details">
          <v-col cols="12" md="6">
            <v-card-text>
              <b>Details : </b><br />
              {{ hub.details }}
            </v-card-text>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { TermHubsService } from '@/services/term-hub-service';
import { getFullPath } from '../../utils/local';

export default {
  data() {
    return {
      dataLoading: true,
      hub: {},
      service: new TermHubsService()
    };
  },
  computed: {
    getTitle() {
      if(this.hub.type === 1) return "FAQ's"
      if(this.hub.type === 2) return "Term & Conditions"
      if(this.hub.type === 3) return "Privacy & Policy"
      return "FAQ's"
    }
  },

  methods: {
    getFullPath,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm A');
    },

    backRoute() {
      let tab;
      if (this.hub.type == 1) {
        tab = 0;
      } else if (this.hub.type == 2) {
        tab = 1;
      } else if (this.hub.type == 3) {
        tab = 2;
      }

      this.$router.push({
        path: '/term-hubs',
        query: { tab: tab }
      });
    },

    async loadHub() {
      try {
        this.dataLoading = true;
        this.hub = await this.service.fetchOne(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }

      this.dataLoading = false;
    }
  },
  async mounted() {
    await this.loadHub();
  }
};
</script>
