<template>
  <data-table
    :loader="loadData"
    :headers="headers"
    title="Teams"
    :allow-add="user.role === 1 ? true : false"
    @done="$router.back()"
    @add-new="addNew"
    :edit-handler="user.role === 1 ? edit : null"
    :view-handler="user && user.role == 1 ? view : null"
    :delete-handler="user.role === 1 ? deleteTeam : null"
    :key="dataTableKey"
  >
    <template #status="{ item }">
      <v-icon :color="item.status === true ? 'green' : 'red'">
        {{ item.status === true ? 'mdi-check' : 'mdi-close' }}
      </v-icon>
    </template>

    <template #createdAt="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>

    <template #updatedAt="{ item }">
      {{ formatDate(item.updatedAt) }}
    </template>
  </data-table>
</template>

<script>
import { TeamsService } from '@/services/teams-service';
import DataTable from '../../components/DataTable';
import { getUserScopes } from '@/utils/local';
import { getUser } from '@/utils/local';
import dayjs from 'dayjs';

export default {
  components: { DataTable },

  data: () => ({
    dataTableKey: 0,
    user: getUser(),
    service: new TeamsService(),
    userScopes: getUserScopes(),
    localUser: getUser(),

    headers: [
      {
        text: 'Title',
        value: 'title',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      },
      {
        text: 'Updated At',
        value: 'updatedAt',
        sortable: true
      }
    ]
  }),

  methods: {
    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY - hh:mm A');
    },

    addNew() {
      this.$router.push('/team');
    },

    edit(item) {
      this.$router.push(`/team?id=${item._id}`);
    },

    view(item) {
      this.$router.push(`/team-details?id=${item._id}`);
    },

    async deleteTeam(item) {
      await this.service.delete(item);
    },

    async loadData() {
      let data = await this.service.fetchAllByBusinessAdminId(
        this.localUser._id
      );

      data.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      return data;
    }
  }
};
</script>
