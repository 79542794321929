import QuestionView from './QuestionView';
import QuestionShow from './QuestionShow';
import QuestionForm from './QuestionForm.vue';
import { getUserScopes } from '../../utils/local';
import { getUser } from '../../utils/local';

export const assessmentQuestionsRoutes = [
  getUser()?.role === 0 || getUserScopes()?.includes('assessmentQuestions:view')
    ? {
        name: 'AssessmentQuestions',
        path: '/assessment-questions',
        component: QuestionView
      }
    : {
        path: '/assessment-questions',
        redirect: '/no-permission'
      },

  getUser()?.role === 0 ||
  getUserScopes()?.includes('assessmentQuestions:create')
    ? {
        name: 'NewAssessmentQuestion',
        path: '/assessment-question',
        component: QuestionForm
      }
    : {
        path: '/assessment-question',
        redirect: '/no-permission'
      },
  getUser()?.role === 0 || getUserScopes()?.includes('assessmentQuestions:view')
    ? {
        name: 'AssessmentQuestionDetails',
        path: '/assessment-question-details',
        component: QuestionShow
      }
    : {
        path: '/assessment-question-details',
        redirect: '/no-permission'
      }
];

export const assessmentQuestionsRouter = assessmentQuestionsRoutes.filter(
  function (x) {
    return x !== null;
  }
);
