import axios from 'axios';

export class AdminConfigurationService {
  async fetchAll() {
    return (await axios.get('/admin-configuration')).data;
  }

  async fetchByAdminId(id) {
    return (await axios.get('/admin-configuration/?adminId=' + id)).data;
  }

  async create(admin) {
    return (await axios.post('/admin-configuration', admin)).data;
  }

  async delete(admin) {
    return (await axios.delete('/admin-configuration' + admin._id)).data;
  }
}
