<template>
  <SimpleForm
    :is-edit="isEdit"
    :disabled="disabled"
    :onSubmit="submit"
    @done="$router.push('/stops')"
  >
    <v-row no-gutters class="d-flex span-2">
      <v-btn
        color="primary"
        style="margin-right: 140px"
        outlined
        @click="$router.back()"
      >
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <p class="form__title">
        {{ isEdit ? 'Update Stop' : 'Add New Stop' }}
      </p>
    </v-row>

    <v-text-field
      v-model="data.title"
      :rules="[required('Title must be provided')]"
      label="Title"
      outlined
      class="span-2"
    ></v-text-field>

    <v-img
      v-if="data.thumbnail"
      :src="thumbnailViewUrl ? thumbnailViewUrl : data.thumbnail"
      :height="300"
      contain
      class="span-2 mb-10"
    ></v-img>

    <v-file-input
      v-model="thumbnail"
      :rules="[!isEdit ? required('Thumbnail must be provided') : true]"
      class="span-2"
      label="Thumbnail"
      outlined
      accept="image/png, image/jpeg, image/jpg, image/gif"
      @change="() => (thumbnailViewUrl = '')"
      @click:clear="() => (data.thumbnail = '')"
    ></v-file-input>

    <v-col cols="12" class="d-flex justify-space-between span-2">
      <p class="form__title">Audios</p>

      <v-btn
        v-if="!isEdit"
        color="primary"
        outlined
        @click="
          audios.push({
            title: '',
            author: '',
            thumbnail: null,
            link: null
          });

          data.audios.push({
            title: '',
            author: '',
            thumbnail: '',
            link: ''
          });

          audiosViewUrl.push({
            thumbnail: null,
            link: null
          });

          scrollToBottom();
        "
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </v-col>

    <v-col
      v-for="(audio, index) in audios"
      :key="index"
      cols="12"
      class="span-2"
    >
      <v-col cols="12" class="span-2 d-flex justify-end">
        <v-btn
          small
          color="red"
          outlined
          @click="!isEdit ? audios.splice(index, 1) : deleteAudio(index)"
        >
          <v-icon small color="red"> mdi-delete </v-icon>
        </v-btn>
      </v-col>

      <v-text-field
        v-model="audio.title"
        :rules="[required('Title must be provided')]"
        label="Title"
        outlined
        :disabled="isEdit ? true : false"
      ></v-text-field>

      <v-text-field
        v-model="audio.author"
        :rules="[required('Author must be provided')]"
        label="Author"
        outlined
        :disabled="isEdit ? true : false"
      ></v-text-field>

      <v-img
        v-if="
          !isEdit
            ? data.audios[index].thumbnail
            : audiosViewUrl[index].thumbnail
        "
        :src="
          !isEdit
            ? data.audios[index].thumbnail
            : audiosViewUrl[index].thumbnail
        "
        :height="300"
        contain
        class="span-2 mb-8"
      ></v-img>

      <v-file-input
        v-model="audio.thumbnail"
        :rules="[!isEdit ? required('Thumbnail must be provided') : true]"
        class="span-2"
        label="Thumbnail"
        outlined
        accept="image/png, image/jpeg, image/jpg, image/gif"
        :disabled="isEdit ? true : false"
        @click:clear="() => (data.audios[index].thumbnail = '')"
      ></v-file-input>

      <v-col class="span-2 d-flex justify-center">
        <audio
          v-if="!isEdit ? data.audios[index].link : audiosViewUrl[index].link"
          :src="!isEdit ? data.audios[index].link : audiosViewUrl[index].link"
          controls
          class="mb-4"
        ></audio>
      </v-col>

      <v-file-input
        v-model="audio.link"
        :rules="[!isEdit ? required('Audio must be provided') : true]"
        class="span-2"
        label="Audio"
        outlined
        accept="audio/*"
        :disabled="isEdit ? true : false"
        @click:clear="() => (data.audios[index].link = '')"
      ></v-file-input>
    </v-col>

    <v-col cols="12" class="d-flex justify-space-between span-2" v-if="isEdit">
      <p class="form__title">New Audios</p>

      <v-btn
        color="primary"
        outlined
        @click="
          newAudios.push({
            title: '',
            author: '',
            thumbnail: null,
            link: null
          });

          newAudiosViewUrl.push({
            thumbnail: null,
            link: null
          });

          scrollToBottom();
        "
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </v-col>

    <v-col
      v-for="(audio, index) in newAudios"
      :key="index + 100"
      cols="12"
      class="span-2"
    >
      <v-col cols="12" class="span-2 d-flex justify-end">
        <v-btn small color="red" outlined @click="newAudios.splice(index, 1)">
          <v-icon small color="red"> mdi-delete </v-icon>
        </v-btn>
      </v-col>

      <v-text-field
        v-model="audio.title"
        :rules="[required('Title must be provided')]"
        label="Title"
        outlined
      ></v-text-field>

      <v-text-field
        v-model="audio.author"
        :rules="[required('Author must be provided')]"
        label="Author"
        outlined
      ></v-text-field>

      <v-img
        v-if="newAudiosViewUrl[index].thumbnail"
        :src="newAudiosViewUrl[index].thumbnail"
        :height="300"
        contain
        class="span-2 mb-8"
      ></v-img>

      <v-file-input
        v-model="audio.thumbnail"
        :rules="[!isEdit ? required('Thumbnail must be provided') : true]"
        class="span-2"
        label="Thumbnail"
        outlined
        accept="image/png, image/jpeg, image/jpg, image/gif"
        @click:clear="() => (newAudiosViewUrl[index].thumbnail = '')"
      ></v-file-input>

      <v-col class="span-2 d-flex justify-center">
        <audio
          v-if="newAudiosViewUrl[index].link"
          :src="newAudiosViewUrl[index].link"
          controls
          class="mb-4"
        ></audio>
      </v-col>

      <v-file-input
        v-model="audio.link"
        :rules="[!isEdit ? required('Audio must be provided') : true]"
        class="span-2"
        label="Audio"
        outlined
        accept="audio/*"
        @click:clear="() => (newAudiosViewUrl[index].link = '')"
      ></v-file-input>
    </v-col>

    <loading-dialog v-model="loading" message="Please Wait..." />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { StopService } from '@/services/stop-service';
import LoadingDialog from '../../components/LoadingDialog';
import { required, httpLink } from '../../utils/validators';
import { uploadFile, getFullPath, deleteFile } from '../../utils/local';
import { getUser } from '@/utils/local';

export default {
  name: 'AudioForm',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    user: getUser(),
    isEdit: false,
    loading: false,
    service: new StopService(),

    // only for edit
    disabled: false,

    thumbnail: null,
    audios: [
      {
        title: '',
        author: '',
        thumbnail: null,
        link: null
      }
    ],
    newAudios: [],
    newAudiosViewUrl: [],

    thumbnailViewUrl: null,
    audiosViewUrl: [
      {
        thumbnail: null,
        link: null
      }
    ],

    oldThumbnail: null,
    oldAudios: [],

    data: {
      title: '',
      thumbnail: '',
      createdBy: '',
      audios: [
        {
          title: '',
          thumbnail: '',
          author: '',
          link: ''
        }
      ]
    }
  }),

  mounted() {
    this.loadData();
    this.data.createdBy = this.user._id;
  },

  watch: {
    thumbnail: {
      handler: function (val) {
        if (!val) return;
        this.data.thumbnail = URL.createObjectURL(val);
      }
    },

    audios: {
      handler: function (val) {
        if (!val) return;

        val.forEach((audio, index) => {
          if (!audio.thumbnail) return;
          this.data.audios[index].thumbnail = URL.createObjectURL(
            audio.thumbnail
          );

          if (!audio.link) return;
          this.data.audios[index].link = URL.createObjectURL(audio.link);
        });
      },
      deep: true
    },

    newAudios: {
      handler: function (val) {
        if (!val) return;

        val.forEach((audio, index) => {
          if (!audio.thumbnail) return;
          this.newAudiosViewUrl[index].thumbnail = URL.createObjectURL(
            audio.thumbnail
          );

          if (!audio.link) return;
          this.newAudiosViewUrl[index].link = URL.createObjectURL(audio.link);
        });
      },
      deep: true
    }
  },

  methods: {
    required,
    httpLink,
    uploadFile,
    getFullPath,
    deleteFile,

    scrollToBottom() {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    },

    async deleteAudio(index) {
      this.loading = true;
      await this.service.deleteAudioFromStop(
        this.oldAudios[index]._id,
        this.data._id
      );
      if (this.oldAudios[index].thumbnail) {
        await this.deleteFile(this.oldAudios[index].thumbnail);
      }
      if (this.oldAudios[index].link) {
        await this.deleteFile(this.oldAudios[index].link);
      }
      this.data.audios.splice(index, 1);
      this.audios.splice(index, 1);
      this.audiosViewUrl.splice(index, 1);
      this.loading = false;
    },

    async loadData() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.data = await this.service.fetchOne(this.$route.query.id);
      this.audios = this.data.audios.map((audio) => ({
        title: audio.title,
        author: audio.author
      }));

      this.thumbnailViewUrl = this.getFullPath(this.data.thumbnail);
      this.audiosViewUrl = this.data.audios.map((audio) => ({
        thumbnail: this.getFullPath(audio.thumbnail),
        link: this.getFullPath(audio.link)
      }));

      this.oldThumbnail = this.data.thumbnail;
      this.oldAudios = this.data.audios;

      this.loading = false;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Stop');

        try {
          if (this.thumbnail) {
            const imageResponse = await this.uploadFile(this.thumbnail);
            this.data.thumbnail = imageResponse.data.name;
          }

          if (this.newAudios.length > 0) {
            await Promise.all(
              this.newAudios.map(async (audio) => {
                if (audio.thumbnail) {
                  const audioThumbnailResponse = await this.uploadFile(
                    audio.thumbnail
                  );
                  audio.thumbnail = audioThumbnailResponse.data.name;
                }

                if (audio.link) {
                  // get audio duration
                  const audioFile = audio.link;
                  const audioElement = document.createElement('audio');
                  audioElement.src = URL.createObjectURL(audioFile);
                  let audioDuration = await new Promise((resolve) => {
                    audioElement.addEventListener('loadedmetadata', () => {
                      resolve(audioElement.duration);
                    });
                  });
                  audioDuration =
                    '0' +
                    Math.floor(audioDuration / 60) +
                    ':' +
                    Math.floor(audioDuration % 60);

                  audio.duration = audioDuration;

                  const audioLinkResponse = await this.uploadFile(audio.link);
                  audio.link = audioLinkResponse.data.name;
                }

                if (audio.thumbnail && audio.link) {
                  return await this.service.addAudioToStop(
                    audio,
                    this.data._id
                  );
                } else {
                  return;
                }
              })
            );
          }

          this.data.createdBy = this.user._id;
          await this.service.update(this.data);
          this.$toast.success('Stop updated successfully');
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while updating Stop',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });
          return false;
        }
      } else {
        context.changeLoadingMessage('Creating Stop');
        try {
          const imageResponse = await this.uploadFile(this.thumbnail);
          this.data.thumbnail = imageResponse.data.name;

          if (this.audios.length > 0 && this.audios[0].title !== '') {
            const audios = await Promise.all(
              this.audios.map(async (audio) => {
                const audioThumbnailResponse = await this.uploadFile(
                  audio.thumbnail
                );
                const audioLinkResponse = await this.uploadFile(audio.link);

                // get audio duration
                const audioFile = audio.link;
                const audioElement = document.createElement('audio');
                audioElement.src = URL.createObjectURL(audioFile);
                let audioDuration = await new Promise((resolve) => {
                  audioElement.addEventListener('loadedmetadata', () => {
                    resolve(audioElement.duration);
                  });
                });
                audioDuration =
                  '0' +
                  Math.floor(audioDuration / 60) +
                  ':' +
                  Math.floor(audioDuration % 60);

                return {
                  title: audio.title,
                  author: audio.author,
                  thumbnail: audioThumbnailResponse.data.name,
                  link: audioLinkResponse.data.name,
                  duration: audioDuration
                };
              })
            );
            this.data.audios = audios;
          } else {
            this.data.audios = [];
          }

          await this.service.create(this.data);
          this.$toast.success('Stop created successfully');
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating Stop',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });

          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
