<template>
  <v-card style="background-color: #424242">
    <v-tabs v-model="tab" dark color="primary">
      <v-tab v-if="user.role === 0"> Behavioral Intelligence </v-tab>
      <v-tab v-if="user.role === 0"> Wheel Of Life </v-tab>
      <v-tab> Additional Assessment </v-tab>
    </v-tabs>

    <data-table
      :loader="loadData"
      :headers="getHeaders"
      title="Assessment Questions"
      :allow-add="
        user && user.role == 0
          ? true
          : null || userScopes.includes('assessmentQuestions:create')
          ? true
          : null
      "
      @done="$router.back()"
      @add-new="addNew"
      :view-handler="
        user && user.role == 0
          ? view
          : null || userScopes.includes('assessmentQuestions:view')
          ? view
          : null
      "
      :edit-handler="
        user && user.role == 0
          ? edit
          : null || userScopes.includes('assessmentQuestions:edit')
          ? edit
          : null
      "
      :delete-handler="
        user && user.role == 0
          ? deleteQuestion
          : null || userScopes.includes('assessmentQuestions:delete')
          ? deleteQuestion
          : null
      "
      :key="tab"
    >
      <template #primary-action>
        <v-btn
          :style="[$vuetify.breakpoint.width < 800 ? { width: 100 + '%' } : '']"
          outlined
          dark
          elevation="4"
          @click="openModal"
        >
          Order
        </v-btn>
      </template>

      <template #question="{ item }">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              {{
                item.question.length > 30
                  ? item.question.substr(0, 30) + '...'
                  : item.question
              }}
            </div>
          </template>
          <span>{{ item.question }}</span>
        </v-tooltip>
      </template>

      <template #link="{ item }">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              {{
                item.link.length > 30
                  ? item.link.substr(0, 30) + '...'
                  : item.link
              }}
            </div>
          </template>
          <span>{{ item.link }}</span>
        </v-tooltip>
      </template>

      <template #createdBy="{ item }">
        {{ item.businessAdminId ? 'Business Admin' : 'Super Admin' }}
      </template>

      <template #createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </data-table>

    <v-dialog persistent width="90%" v-model="resetOrderModal">
      <v-card class="elevation-0 height-full py-4 dark-mode">
        <div class="d-flex justify-space-between items-center mb-2">
          <v-card-title
            class="d-flex flex justify-betwwen"
            :style="$vuetify.breakpoint.mdAndUp ? '' : 'gap: 10px'"
          >
            <span class="text-h5">Rearrange Order</span>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              elevation="0"
              class="mr-2 btn-text"
              :disabled="loading"
              @click="saveArrangedItems"
            >
              <v-icon class="v-btn__pre-icon" small dark>mdi-content-save </v-icon
              >&nbsp; {{ loading ? 'Saving...' : 'Save Changes' }}
            </v-btn>

            <v-btn icon @click="closeModal">
              <v-icon class="v-btn__pre-icon" small dark>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </div>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />

        <div class="px-4">
          <div
            class="d-flex items-center justify-space-around border-bottom pb-3 px-2"
          > 
            <h2
              class="flex-1 column-header dark-mode"
              v-for="(header, key) in getHeaders"
              :key="key"
            >
              {{ header.text }}
            </h2>
          </div>
          <Container @drop="onDrop" v-if="questionsClone.length">
            <Draggable
              v-for="(item, index) in questionsClone"
              :key="item._id + index"
            >
              <div
                :class="`d-flex items-center item-row border-bottom px-2 pt-2`" style="border-color: white;"
              >
                <p class="flex-1" v-if="getHeadersValues.includes('question')">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        {{
                          item.question.length > 30
                            ? item.question.substr(0, 30) + '...'
                            : item.question
                        }}
                      </div>
                    </template>
                    <span>{{ item.question }}</span>
                  </v-tooltip>
                </p>

                <p class="flex-1" v-if="getHeadersValues.includes('scoreType')">
                  {{ item.scoreType }}
                </p>

                <p class="flex-1" v-if="getHeadersValues.includes('name')">
                  {{ item.name }}
                </p>

                <p class="flex-1" v-if="getHeadersValues.includes('link')">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        {{
                          item.link.length > 30
                            ? item.link.substr(0, 30) + '...'
                            : item.link
                        }}
                      </div>
                    </template>
                    <span>{{ item.link }}</span>
                  </v-tooltip>
                </p>

                <p class="flex-1">
                  {{ item.businessAdminId ? 'Business Admin' : 'Super Admin' }}
                </p>

                <p class="flex-1">{{ formatDate(item.createdAt) }}</p>
              </div>
            </Draggable>
          </Container>
          <div
            v-if="questionsClone.length === 0"
            class="d-flex justify-center align-center height-full"
          >
            No Data Found
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { AssessmentQuestionsService } from '@/services/assessment-questions-service';
import DataTable from '../../components/DataTable';
import { getUserScopes } from '@/utils/local';
import { getUser } from '@/utils/local';
import dayjs from 'dayjs';
import { Container, Draggable } from 'vue-smooth-dnd';

export default {
  components: { DataTable, Container, Draggable },

  mounted() {
    if (this.$route.query.tab == 0) {
      this.tab = 0;
    } else if (this.$route.query.tab == 1) {
      this.tab = 1;
    } else if (this.$route.query.tab == 2) {
      this.tab = 2;
    } else {
      this.tab = 0;
    }
  },

  computed: {
    isForBAdmin() {
      return this.tab === 2 || this.user.role === 1;
    },
    getHeaders() {
      return this.isForBAdmin ? this.headers2 : this.headers1;
    },
    getHeadersValues() {
      return this.getHeaders.map(header => header.value);
    }
  },

  data: () => ({
    tab: 0,
    user: getUser(),
    service: new AssessmentQuestionsService(),
    userScopes: getUserScopes(),
    questions: [],

    headers1: [
      {
        text: 'Question',
        value: 'question',
        sortable: true
      },
      {
        text: 'Score Type',
        value: 'scoreType',
        sortable: true
      },
      {
        text: 'Created By',
        value: 'createdBy',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      }
    ],

    headers2: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      {
        text: 'Link',
        value: 'link',
        sortable: true
      },
      {
        text: 'Created By',
        value: 'createdBy',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      }
    ],
    resetOrderModal: false,
    loading: false,
    questionsClone: []
  }),

  methods: {
    openModal() {
      this.questionsClone = [...this.questions];
      this.resetOrderModal = true;
    },
    closeModal() {
      this.questionsClone = [];
      this.resetOrderModal = false;
    },
    onDrop(dropResult) {
      this.questionsClone = this.applyDrag(this.questionsClone, dropResult);
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },
    async saveArrangedItems() {
      try {
        if (this.questionsClone.length) {
          this.loading = true;

          const updateOrderInQuestions = this.questionsClone.map(
            (question, index) => {
              let obj = {};
              obj['_id'] = question._id;
              obj['order'] = index + 1;
              return obj;
            }
          );

          let assessmentType;

          if (
            this.questionsClone[0].assessmentType === 'Behavioral Intelligence'
          ) {
            assessmentType = 1;
          } else if (
            this.questionsClone[0].assessmentType === 'Wheel Of Life'
          ) {
            assessmentType = 2;
          } else if (
            this.questionsClone[0].assessmentType === 'Additional Assessment'
          ) {
            assessmentType = 3;
          }

          await this.service.updateOrder({
            assessmentType,
            ids: updateOrderInQuestions.map((q) => q._id)
          });

          document.getElementById('refresh').click();
          this.loading = false;
          this.closeModal();
          this.$toast.success('Successfully updated');
        } else {
          this.$toast.error('No data to update');
        }
      } catch (error) {
        console.error('Error updating data:', error);
        this.$toast.error('Something went wrong');
      }
    },
    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY - hh:mm A');
    },

    addNew() {
      this.user.role === 0
        ? this.$router.push(`/assessment-question?tab=${this.tab}`)
        : this.$router.push('/assessment-question?tab=2');
    },

    edit(item) {
      this.$router.push(`/assessment-question?id=${item._id}&tab=${this.tab}`);
    },

    deleteQuestion(item) {
      this.service.delete(item._id);
    },

    view(item) {
      this.$router.push(`/assessment-question-details?id=${item._id}`);
    },
    onRowOrderChange($event) {
      console.log('event: ', $event);
    },

    async loadData() {
      if (this.user.role === 0) {
        this.questions = await this.service.fetchAll();

        // assessment type
        this.questions = this.questions.map((item) => {
          if (item.assessmentType == 1) {
            item.assessmentType = 'Behavioral Intelligence';
          } else if (item.assessmentType == 2) {
            item.assessmentType = 'Wheel Of Life';
          } else if (item.assessmentType == 3) {
            item.assessmentType = 'Additional Assessment';
          }
          return item;
        });

        // score type
        this.questions = this.questions.map((item) => {
          if (item.scoreType == 1) {
            item.scoreType = 'Stop';
          } else if (item.scoreType == 2) {
            item.scoreType = 'Think';
          } else if (item.scoreType == 3) {
            item.scoreType = 'Assess';
          } else if (item.scoreType == 4) {
            item.scoreType = 'Respond';
          } else if (item.scoreType == 5) {
            item.scoreType = 'Review';
          } else if (item.scoreType == 6) {
            item.scoreType = 'General';
          } else if (item.scoreType == 7) {
            item.scoreType = 'GlobalBIQ';
          } else if (item.scoreType == 8) {
            item.scoreType = 'Validity';
          }
          return item;
        });

        if (this.tab == 0) {
          this.questions = this.questions.filter(
            (item) => item.assessmentType === 'Behavioral Intelligence'
          );
        } else if (this.tab == 1) {
          this.questions = this.questions.filter(
            (item) => item.assessmentType === 'Wheel Of Life'
          );
        } else if (this.tab == 2) {
          this.questions = this.questions.filter(
            (item) => item.assessmentType === 'Additional Assessment'
          );
        }

        return this.questions;
      } else {
        this.questions = await this.service.fetchAllByUserId(this.user._id);

        // score type
        this.questions = this.questions.map((item) => {
          if (item.scoreType == 1) {
            item.scoreType = 'Stop';
          } else if (item.scoreType == 2) {
            item.scoreType = 'Think';
          } else if (item.scoreType == 3) {
            item.scoreType = 'Assess';
          } else if (item.scoreType == 4) {
            item.scoreType = 'Respond';
          } else if (item.scoreType == 5) {
            item.scoreType = 'Review';
          } else if (item.scoreType == 6) {
            item.scoreType = 'General';
          } else if (item.scoreType == 7) {
            item.scoreType = 'GlobalBIQ';
          } else if (item.scoreType == 8) {
            item.scoreType = 'Validity';
          }
          return item;
        });

        // remove questions created by super admin
        this.questions = this.questions.filter(
          (item) => item.businessAdminId !== null
        );

        return this.questions;
      }
    }
  }
};
</script>

<style scoped>
.column-header {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

.item-row {
  font-size: 0.875rem;
  height: 48px;
}

.border-bottom {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.flex-1 {
  flex: 1;
}

p {
  margin: 0;
}

/* .height-full {
  height: 80vh;
} */

.data-table__header {
  font-size: 25px;
  font-family: google-sans, sans-serif;
}

.btn-text {
  color: #ffffff;
}

.delete-btn {
  cursor: pointer;
}

.delete-row {
  text-decoration: line-through;
}

.newly_added {
  background-color: rgba(195, 253, 157, 0.4);
}

.dark-mode {
  background-color: #1E1E1E;
  color: white;
}
</style>
