import axios from 'axios';

export class UsersService {
  async fetchAllUsers() {
    return (await axios.get('/person?role=3')).data;
  }

  async fetchAllBusinessUsers() {
    return (await axios.get('/person?role=2')).data;
  }

  async fetchAllBusinessUsersByAdminId(id) {
    return (await axios.get('/person/' + id + '/business-users')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/person/' + id)).data;
  }

  async create(admin) {
    return (await axios.post('/auth/sign-up', admin)).data;
  }

  async update(admin) {
    return (await axios.patch('/person/' + admin._id, admin)).data;
  }

  async delete(admin) {
    return (await axios.delete('/person/' + admin._id)).data;
  }

  async disable(id, status) {
    return (await axios.patch('/person/' + id + '/update-status', status)).data;
  }
}
