<template>
  <data-table
    :loader="loadData"
    :headers="headers"
    title="Business Admins"
    :allow-add="user && user.role === 0 ? true : false"
    @add-new="addNew"
    @done="$router.back()"
    :edit-handler="user && user.role === 0 ? edit : null"
    :disable-handler="user && user.role === 0 ? disableHandler : null"
    :key="dataTableKey"
  >
    <template #status="{ item }">
      <v-icon :color="item.status === true ? 'green' : 'red'">
        {{ item.status === true ? 'mdi-check' : 'mdi-close' }}
      </v-icon>
    </template>

    <template #createdAt="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
  </data-table>
</template>

<script>
import { AdminsService } from '@/services/admin-service';
import DataTable from '../../components/DataTable';
import { getUserScopes } from '@/utils/local';
import { getUser } from '@/utils/local';
import dayjs from 'dayjs';

export default {
  components: { DataTable },

  data: () => ({
    dataTableKey: 0,
    admin_service: new AdminsService(),
    userScopes: getUserScopes(),
    user: getUser(),

    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      {
        text: 'Email',
        value: 'email',
        sortable: true
      },
      {
        text: 'Status',
        value: 'status',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      }
    ]
  }),

  methods: {
    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY - hh:mm A');
    },

    addNew() {
      this.$router.push('/business-admin');
    },

    edit(item) {
      this.$router.push(`/business-admin?id=${item._id}`);
    },

    view(item) {
      this.$router.push(`/business-admin-details?id=${item._id}`);
    },

    async disableHandler(item) {
      try {
        await this.admin_service.disable(item._id, {
          status: item.status === true ? false : true
        });
        let toastMessage =
          item.status === true
            ? 'Business Admin disabled successfully'
            : 'Business Admin enabled successfully';
        this.$toast.success(toastMessage);
        this.dataTableKey += 1;
      } catch (e) {
        this.$toast.error('Error disabling Business Admin');
      }
    },

    async loadData() {
      return await this.admin_service.fetchBusinessAdmins();
    }
  }
};
</script>
