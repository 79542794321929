import View from './View';
import BooksForm from './BooksForm.vue';
import BooksShow from './BooksShow.vue';
import GuidelinesForm from './GuidelinesForm.vue'
import GuidelinesShow from './GuidelinesShow.vue';
import VideosForm from './VideosForm.vue';
import VideosShow from './VideosShow.vue';
import LinksForm from './LinksForm.vue';
import LinksShow from './LinksShow.vue';
import AudiosForm from './AudiosForm.vue';
import AudiosShow from './AudiosShow.vue';
import { getUser } from '../../utils/local';
import { getUserScopes } from '../../utils/local';

export const libraryRoutes = [
  getUser()?.role === 0 || getUserScopes()?.includes('library:view')
    ? {
        name: 'library',
        path: '/library-all',
        component: View
      }
    : {
        path: '/library-all',
        redirect: '/no-permission'
      },

  getUser()?.role === 0 ||
  getUserScopes()?.includes('library:create') ||
  getUserScopes()?.includes('library:edit')
    ? {
        name: 'NewLibraryBook',
        path: '/library/book',
        component: BooksForm
      }
    : {
        path: '/library/book',
        redirect: '/no-permission'
      },

  getUser()?.role === 0 || getUserScopes()?.includes('library:view')
    ? {
        name: 'LibraryBookDetails',
        path: '/library-book-details',
        component: BooksShow
      }
    : {
        path: '/library-book-details',
        redirect: '/no-permission'
      },

  getUser()?.role === 0 ||
  getUserScopes()?.includes('library:create') ||
  getUserScopes()?.includes('library:edit')
    ? {
        name: 'NewLibraryVideo',
        path: '/library/video',
        component: VideosForm
      }
    : {
        path: '/library/video',
        redirect: '/no-permission'
      },

  getUser()?.role === 0 || getUserScopes()?.includes('library:view')
    ? {
        name: 'LibraryVideoDetails',
        path: '/library-video-details',
        component: VideosShow
      }
    : {
        path: '/library-video-details',
        redirect: '/no-permission'
      },

      getUser()?.role === 0 ||
  getUserScopes()?.includes('library:create') ||
  getUserScopes()?.includes('library:edit')
    ? {
        name: 'NewLibraryGuidelines',
        path: '/library/guidelines',
        component: GuidelinesForm
      }
    : {
        path: '/library/guidelines',
        redirect: '/no-permission'
      },

  getUser()?.role === 0 || getUserScopes()?.includes('library:view')
    ? {
        name: 'LibraryGuidelinesDetails',
        path: '/library-guidelines-details',
        component: GuidelinesShow
      }
    : {
        path: '/library-guidelines-details',
        redirect: '/no-permission'
      },


  getUser()?.role === 0 ||
  getUserScopes()?.includes('library:create') ||
  getUserScopes()?.includes('library:edit')
    ? {
        name: 'NewLibraryLink',
        path: '/library/link',
        component: LinksForm
      }
    : {
        path: '/library/link',
        redirect: '/no-permission'
      },

  getUser()?.role === 0 || getUserScopes()?.includes('library:view')
    ? {
        name: 'LibraryLinkDetails',
        path: '/library-link-details',
        component: LinksShow
      }
    : {
        path: '/library-link-details',
        redirect: '/no-permission'
      },

  getUser()?.role === 0 ||
  getUserScopes()?.includes('library:create') ||
  getUserScopes()?.includes('library:edit')
    ? {
        name: 'NewLibraryAudio',
        path: '/library/audio',
        component: AudiosForm
      }
    : {
        path: '/library/audio',
        redirect: '/no-permission'
      },

  getUser()?.role === 0 || getUserScopes()?.includes('library:view')
    ? {
        name: 'LibraryAudioDetails',
        path: '/library-audio-details',
        component: AudiosShow
      }
    : {
        path: '/library-audio-details',
        redirect: '/no-permission'
      }
];

export const libraryRouter = libraryRoutes.filter(function (x) {
  return x !== null;
});
