import AnswerView from './AnswerView';
import AnswerShow from './AnswerShow';
import { getUserScopes } from '../../utils/local';
import { getUser } from '../../utils/local';

export const assessmentAnswersRoutes = [
  getUser()?.role === 0 || getUserScopes()?.includes('assessmentAnswers:view')
    ? {
        name: 'AssessmentAnswers',
        path: '/assessment-answers',
        component: AnswerView
      }
    : {
        path: '/assessment-answers',
        redirect: '/no-permission'
      },
  getUser()?.role === 0 || getUserScopes()?.includes('assessmentAnswers:view')
    ? {
        name: 'AssessmentAnswerDetails',
        path: '/assessment-answer-details',
        component: AnswerShow
      }
    : {
        path: '/assessment-answer-details',
        redirect: '/no-permission'
      }
];

export const assessmentAnswersRouter = assessmentAnswersRoutes.filter(function (
  x
) {
  return x !== null;
});
