<template>
  <SimpleForm
    :is-edit="isEdit"
    :disabled="disabled"
    :onSubmit="submit"
    @done="$router.push('/library-all?tab=0')"
  >
    <v-row no-gutters class="d-flex span-2">
      <v-btn
        color="primary"
        style="margin-right: 140px"
        outlined
        @click="$router.back()"
      >
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <p class="form__title">
        {{ isEdit ? 'Update Book' : 'Add New Book' }}
      </p>
    </v-row>

    <v-text-field
      v-model="data.books.title"
      :rules="[required('Title must be provided')]"
      label="Title"
      outlined
      class="span-2"
    ></v-text-field>

    <v-text-field
      v-model="data.books.author"
      :rules="[required('Author must be provided')]"
      label="Author"
      outlined
      class="span-2"
    ></v-text-field>

    <v-img
      v-if="data.books.thumbnail"
      :src="thumbnailViewUrl ? thumbnailViewUrl : data.books.thumbnail"
      :height="300"
      contain
      class="span-2 mb-10"
    ></v-img>

    <v-file-input
      v-model="thumbnail"
      :rules="[!isEdit ? required('Thumbnail must be provided') : true]"
      class="span-2"
      :label="isEdit ? 'Change Thumbnail' : 'Thumbnail'"
      outlined
      accept="image/png, image/jpeg, image/jpg, image/gif"
      @change="() => (thumbnailViewUrl = '')"
      @click:clear="() => (data.books.thumbnail = '')"
    ></v-file-input>

    <iframe
      v-if="data.books.link"
      :src="pdfViewUrl ? pdfViewUrl : data.books.link"
      height="300px"
      width="100%"
      frameborder="0"
      class="span-2 mb-8"
    ></iframe>

    <v-file-input
      v-model="pdf"
      :rules="[!isEdit ? required('pdf  must be provided') : true]"
      class="span-2"
      :label="isEdit ? 'Change pdf' : 'pdf'"
      outlined
      accept="application/pdf"
      @change="() => (pdfViewUrl = '')"
      @click:clear="() => (data.books.link = '')"
    ></v-file-input>

    <loading-dialog v-model="loading" message="Fetching Book Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { LibraryService } from '@/services/library-service';
import LoadingDialog from '../../components/LoadingDialog';
import { required } from '../../utils/validators';
import { uploadFile, getFullPath, deleteFile } from '../../utils/local';
import { getUser } from '../../utils/local';

export default {
  name: 'BookForm',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    user: getUser(),
    service: new LibraryService(),

    // only for edit
    disabled: false,

    thumbnail: null,
    pdf: null,

    thumbnailViewUrl: null,
    pdfViewUrl: null,

    oldThumbnail: null,
    oldPdf: null,

    data: {
      itemType: 'BOOK',
      books: {
        title: '',
        thumbnail: '',
        link: '',
        author: ''
      },
      createdBy: ''
    }
  }),

  mounted() {
    this.data.createdBy = this.user._id;
    this.loadData();
  },

  watch: {
    thumbnail: {
      handler: function (val) {
        if (!val) return;
        this.data.books.thumbnail = URL.createObjectURL(val);
      }
    },

    pdf: {
      handler: function (val) {
        if (!val) return;
        this.data.books.link = URL.createObjectURL(val);
      }
    }
  },

  methods: {
    required,
    uploadFile,
    getFullPath,
    deleteFile,

    async loadData() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.data = await this.service.fetchOne(this.$route.query.id);

      this.thumbnailViewUrl = this.getFullPath(this.data.books.thumbnail);
      this.pdfViewUrl = this.getFullPath(this.data.books.link);

      this.oldThumbnail = this.data.books.thumbnail;
      this.oldPdf = this.data.books.link;

      this.loading = false;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Book');

        try {
          if (this.thumbnail) {
            const imageResponse = await this.uploadFile(this.thumbnail);
            this.data.books.thumbnail = imageResponse.data.name;
          }

          if (this.pdf) {
            const documentResponse = await this.uploadFile(this.pdf);
            this.data.books.link = documentResponse.data.name;
          }

          await this.service.update(this.data);

          if (this.thumbnail && this.oldThumbnail) {
            await this.deleteFile(this.oldThumbnail);
          }

          if (this.pdf && this.oldPdf) {
            await this.deleteFile(this.oldPdf);
          }

          this.$toast.success('Book updated successfully');
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while updating Book',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });
          return false;
        }
      } else {
        context.changeLoadingMessage('Creating Book');
        try {
          if (!this.thumbnail) {
            context.reportError({
              title: 'Error while creating Book',
              description: 'Thumbnail must be provided'
            });
            return false;
          }

          if (!this.pdf) {
            context.reportError({
              title: 'Error while creating Book',
              description: 'pdf must be provided'
            });
            return false;
          }

          const imageResponse = await this.uploadFile(this.thumbnail);
          this.data.books.thumbnail = imageResponse.data.name;

          const pdfResponse = await this.uploadFile(this.pdf);
          this.data.books.link = pdfResponse.data.name;

          await this.service.create(this.data);
          this.$toast.success('Book created successfully');
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating Book',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });

          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
