import axios from 'axios';

export class AssessmentAnswersService {
  async fetchAll() {
    return (await axios.get('/assessment-answers')).data;
  }

  async fetchAllByUserId(userId) {
    return (await axios.get('/assessment-answers?userId=' + userId)).data;
  }

  async fetchOne(id) {
    return (await axios.get('/assessment-answers/' + id)).data;
  }

  async create(data) {
    return (await axios.post('/assessment-answers', data)).data;
  }
}
