import UserView from './UserView';
import UserShow from './UserShow';
import UserForm from './UserForm.vue';
import { getUser } from '../../utils/local';

export const usersRoutes = [
  getUser()?.role === 0
    ? {
        name: 'User',
        path: '/users',
        component: UserView
      }
    : {
        path: '/users',
        redirect: '/no-permission'
      },

  getUser()?.role === 0
    ? {
        name: 'NewUser',
        path: '/user',
        component: UserForm
      }
    : {
        path: '/user',
        redirect: '/no-permission'
      },
  getUser()?.role === 0
    ? {
        name: 'userDetails',
        path: '/user-details',
        component: UserShow
      }
    : {
        path: '/user-details',
        redirect: '/no-permission'
      }
];

export const usersRouter = usersRoutes.filter(function (x) {
  return x !== null;
});
