<template>
  <SimpleForm
    :is-edit="isEdit"
    :disabled="disabled"
    :onSubmit="submit"
    @done="$router.back()"
  >
    <v-row no-gutters class="d-flex span-2">
      <v-btn color="primary" outlined @click="$router.back()">
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <p class="form__title" style="margin-left: 120px">
        {{ isEdit ? 'Update Team' : 'Add New Team' }}
      </p>
    </v-row>
    <!-- <v-row no-gutters class="d-flex justify-space-between span-2">
      <p class="form__title">
        {{ isEdit ? 'Update Team' : 'Add New Team' }}
      </p>
      <v-btn color="primary" outlined @click="$router.back()">Back</v-btn>
    </v-row> -->

    <v-text-field
      v-model="team.title"
      :rules="[required('Title must be provided'), requiredV2()]"
      class="span-2"
      label="Title"
      outlined
    />

    <v-select
      v-model="selectedMembers"
      :items="members"
      :rules="[(v) => v.length >= 2 || 'Minimum 2 members must be selected']"
      item-text="name"
      label="Members"
      class="span-2"
      multiple
      outlined
    />

    <v-select
      v-if="selectedMembers && selectedMembers.length > 1"
      v-model="selectedTeamLead"
      :items="selectedMembers"
      :rules="[(v) => v.length > 0 || 'Team Lead must be selected']"
      item-text="name"
      label="Team Lead"
      class="span-2"
      outlined
    />

    <loading-dialog v-model="loading" message="Fetching Team Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { UsersService } from '@/services/users-service';
import { TeamsService } from '@/services/teams-service';
import LoadingDialog from '../../components/LoadingDialog';
import { required, requiredV2 } from '@/utils/validators';
import { getUser } from '@/utils/local';

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    userServices: new UsersService(),
    service: new TeamsService(),
    localUser: getUser(),

    members: [],
    selectedMembers: [],
    selectedTeamLead: '',

    // only for edit
    disabled: false,
    team: {
      title: '',
      members: [
        {
          info: '',
          isTeamLead: false
        }
      ],
      createdBy: ''
    }
  }),

  async mounted() {
    this.team.createdBy = this.localUser._id;
    let members = await this.userServices.fetchAllBusinessUsersByAdminId(
      this.localUser._id
    );
    this.members = members.map((member) => {
      return {
        id: member._id,
        name: member.name
      };
    });

    this.loadUser();
  },

  methods: {
    required,
    requiredV2,

    async loadUser() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.team = await this.service.fetchOne(this.$route.query.id);

      this.selectedMembers = this.team.members.map((member) => {
        return member.info.name;
      });

      this.selectedTeamLead = this.team.members.find((member) => {
        return member.isTeamLead;
      }).info.name;

      this.team.createdBy = this.team.createdBy._id;

      this.loading = false;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Team');

        try {
          this.team.members = this.selectedMembers.map((member) => {
            return {
              info: member,
              isTeamLead: member === this.selectedTeamLead ? true : false
            };
          });

          this.team.members.forEach((member) => {
            this.members.forEach((m) => {
              if (member.info === m.name) {
                member.info = m.id;
              }
            });
          });

          this.service.update(this.team);
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while updating Team',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });
          return false;
        }
      } else {
        context.changeLoadingMessage('Creating Team');
        try {
          this.team.members = this.selectedMembers.map((member) => {
            return {
              info: member,
              isTeamLead: member === this.selectedTeamLead ? true : false
            };
          });

          this.team.members.forEach((member) => {
            this.members.forEach((m) => {
              if (member.info === m.name) {
                member.info = m.id;
              }
            });
          });

          await this.service.create(this.team);
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating Team',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });

          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
