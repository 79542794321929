import axios from 'axios';

export class TeamsService {
  // async fetchAll() {
  //   return (await axios.get('/teams')).data;
  // }

  async fetchAllByBusinessAdminId(id) {
    return (await axios.get('/teams?businessAdminId=' + id)).data;
  }

  async fetchOne(id) {
    return (await axios.get('/teams/' + id)).data;
  }

  async create(team) {
    return (await axios.post('/teams', team)).data;
  }

  async update(team) {
    return (await axios.patch('/teams/' + team._id, team)).data;
  }

  async delete(team) {
    return (await axios.delete('/teams/' + team._id)).data;
  }
}
