<template>
  <div>404 Not Found</div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style scoped></style>