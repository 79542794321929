import axios from 'axios';

export class TermHubsService {
  async fetchAll() {
    return (await axios.get('/terms-hub')).data;
  }

  async fetchAllByType(type) {
    return (await axios.get('/terms-hub?type=' + type)).data;
  }

  async fetchOne(id) {
    return (await axios.get('/terms-hub/' + id)).data;
  }

  async create(data) {
    return (await axios.post('/terms-hub', data)).data;
  }

  async update(data) {
    return (await axios.patch('/terms-hub/' + data._id, data)).data;
  }

  async delete(id) {
    return (await axios.delete('/terms-hub/' + id)).data;
  }
}
