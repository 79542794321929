import Form from './Form.vue';

export const AdminConfigurationRoutes = [
  {
    name: 'Admin Configuration',
    path: '/admin-configuration',
    component: Form
  }
];

export const adminConfigurationRouter = AdminConfigurationRoutes.filter(
  function (x) {
    return x !== null;
  }
);
