var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"background-color":"#424242"}},[_c('v-tabs',{attrs:{"dark":"","color":"primary"},on:{"change":function ($event) {
        _vm.$router.push({ path: '/library-all', query: { tab: $event } });

        if ($event == 0) {
          _vm.tableTitle = 'Articles, Reports & Resources';
        } else if ($event == 1) {
          _vm.tableTitle = 'MyBiQ and STARR guides';
        } else if ($event == 2) {
          _vm.tableTitle = 'Videos';
        } else if ($event == 3) {
          _vm.tableTitle = 'Links';
        } else if ($event == 4) {
          _vm.tableTitle = 'Audios';
        }
      }},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Articles, Reports & Resources ")]),_c('v-tab',[_vm._v(" myBiQ and STARR guides ")]),_c('v-tab',[_vm._v(" Videos ")]),_c('v-tab',[_vm._v(" Links ")]),_c('v-tab',[_vm._v(" Audios ")])],1),_c('data-table',{key:_vm.dataTableKey,attrs:{"loader":_vm.loadData,"headers":_vm.tab === 1 || _vm.tab === 3 || _vm.tab === 4 ? _vm.linksHeader : _vm.headers,"title":_vm.tableTitle,"allow-add":true,"edit-handler":_vm.edit,"delete-handler":_vm.deleteItem,"view-handler":_vm.view},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([(_vm.tab === 0 || _vm.tab === 2)?{key:"author",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.tab === 0 && item.books.author) || (_vm.tab === 2 && item.videos.author))+" ")]}}:null,(_vm.tab === 1)?{key:"title",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.guidelines.items[0] && item.guidelines.items[0].title ? item.guidelines.items[0].title + ',...' : 'NA')+" ")]}}:null,{key:"createdAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }