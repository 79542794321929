import Vue from 'vue';
import VueRouter from 'vue-router';
import SignInView from './views/auth/SignIn';
import NotFound from './views/404.vue';
import Dashboard from './components/Dashboard';

import { homeRouter } from './views/home/router';
import { settingRouter } from './views/setting/router';
import { noPermissionRouter } from './views/permission/router';
import { superAdminsRouter } from './views/superAdmins/router';
import { businessAdminsRouter } from './views/businessAdmins/router';
import { usersRouter } from './views/users/router';
import { businessUsersRouter } from './views/businessUsers/router';
import { assessmentQuestionsRouter } from './views/assessmentQuestions/router';
import { assessmentAnswersRouter } from './views/assessmentAnswers/router';
import { teamsRouter } from './views/teams/router';
// import { journalsRouter } from './views/journals/router';
import { libraryRouter } from './views/library/router';
import { stopRouter } from './views/stops/router';
// import { coachRequestsRouter } from './views/coachRequests/router';
import { adminConfigurationRouter } from './views/adminConfiguration/router';
import { termHubsRouter } from './views/termHub/router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Dashboard,
    children: [
      ...homeRouter,
      ...settingRouter,
      ...superAdminsRouter,
      ...businessAdminsRouter,
      ...usersRouter,
      ...businessUsersRouter,
      ...noPermissionRouter,
      ...assessmentQuestionsRouter,
      ...assessmentAnswersRouter,
      ...teamsRouter,
      // ...journalsRouter,
      ...libraryRouter,
      ...stopRouter,
      // ...coachRequestsRouter
      ...adminConfigurationRouter,
      ...termHubsRouter
    ]
  },
  {
    name: 'SignIn',
    path: '/auth/sign-in',
    component: SignInView
  },
  {
    name: 'NotFound',
    path: '**',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, __, next) => {
  if (!localStorage.getItem('auth_token')) {
    if (to.path !== '/auth/sign-in') {
      next('/auth/sign-in');
    }
  }

  next();
});

export default router;
