<template>
  <SimpleForm
    :is-edit="isEdit"
    :disabled="disabled"
    :onSubmit="submit"
    @done="backRoute"
  >
    <v-row no-gutters class="d-flex span-2">
      <v-btn color="primary" class="mr-10" outlined @click="backRoute">
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <p class="form__title">
        {{ isEdit ? 'Update ' : 'Add' }} {{ getTitle }}
      </p>
    </v-row>

    <v-text-field
      v-if="tab === 0"
      v-model="question"
      :rules="[required('Question must be provided')]"
      class="span-2"
      label="Question"
      outlined
    ></v-text-field>

    <v-text-field
      v-if="tab === 0"
      :rules="[required('Answer must be provided')]"
      v-model="answer"
      class="span-2"
      label="Answer"
      outlined
    ></v-text-field>

    <v-textarea
      v-if="tab !== 0"
      v-model="details"
      outlined
      class="span-2"
      rows="7"
      :rules="[required('Details must be provided')]"
      label="Details"
      placeholder="Please enter details here!"
    ></v-textarea>

    <loading-dialog
      v-model="loading"
      message="Fetching Hub Item Data"
    />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { TermHubsService } from '@/services/term-hub-service';
import LoadingDialog from '../../components/LoadingDialog';
import { required, requiredV2, email } from '@/utils/validators';
import { getUser } from '../../utils/local';

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    service: new TermHubsService(),
    user: getUser(),
    hub: null,
    tab: 0,
    question: null,
    answer: null,
    details: null,

    // only for edit
    disabled: false
  }),

  computed: {
    getTitle() {
      if(this.tab === 0) return "FAQ's"
      if(this.tab === 1) return "term and conditions"
      if(this.tab === 2) return "privacy & policy"
      return "FAQ's"
    }

  },

  async mounted() {
    this.tab = Number(this.$route.query.tab);

    await this.laodHub();
  },

  methods: {
    required,
    requiredV2,
    email,

    backRoute() {
      this.$router.push({
        path: '/term-hubs',
        query: { tab: this.tab }
      });
    },

    async laodHub() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.hub = await this.service.fetchOne(this.$route.query.id);

      if(this.tab === 0) {
        this.question = this.hub.question;
        this.answer = this.hub.answer;

      } else {
        this.details = this.hub.details;
      }
      
      this.loading = false;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Hub Item');

        try {
          let data = {};

          data.createdBy = this.user._id;
          data.type = Number(this.tab) + 1;

          if(this.tab === 0) {
            data.question = this.question;
            data.answer = this.answer;
          } else {
            data.details = this.details
          }
          
          this.service.update(data);
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while updating Hub Item',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });
          return false;
        }
      } else {
        context.changeLoadingMessage('Creating Hub Item');
        try {
          let data = {};
          data.createdBy = this.user._id;
          data.type = Number(this.tab) + 1;

          console.log(this.tab);

          if(this.tab === 0) {
            console.log('sdafsd')
            data.question = this.question;
            data.answer = this.answer;
          } else {
            data.details = this.details
          }

          console.log('data: ', data);
          
          await this.service.create(data);
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating hub item',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });

          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
