<template>
  <SimpleForm
    :is-edit="isEdit"
    :disabled="disabled"
    :onSubmit="submit"
    @done="$router.back()"
  >
    <v-row no-gutters class="d-flex span-2">
      <v-btn color="primary" class="mr-16" outlined @click="$router.back()">
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <p class="form__title">
        {{ isEdit ? 'Update Business Admin' : 'Add New Business Admin' }}
      </p>
    </v-row>

    <v-text-field
      v-model="admin.name"
      :rules="[required('Name must be provided'), requiredV2()]"
      class="span-2"
      label="Name"
      outlined
    ></v-text-field>

    <v-text-field
      v-model="admin.email"
      :rules="[
        required('Email must be provided'),
        email('Email must be valid'),
        requiredV2()
      ]"
      class="span-2"
      label="Email"
      type="email"
      outlined
    />

    <v-text-field
      v-if="!isEdit"
      v-model="admin.password"
      label="Password"
      class="span-2"
      outlined
      :type="showPassword ? 'text' : 'password'"
    />

    <v-checkbox
      v-if="!isEdit"
      class="mt-n5"
      v-model="showPassword"
      :label="`Show password`"
    ></v-checkbox>

    <v-text-field
      v-if="isEdit && loggedInUserId === admin._id"
      v-model="password.old"
      class="span-2"
      label="Old Password(Optional)"
      outlined
    ></v-text-field>

    <v-text-field
      v-if="isEdit && loggedInUserId === admin._id"
      v-model="password.new"
      class="span-2"
      label="New Password(Optional)"
      outlined
    ></v-text-field>

    <div class="span-2">
      <p>Select Business Admin Scopes</p>

      <v-treeview
        v-model="admin.scopes"
        :items="adminScopes"
        selectable
        style="text-align: left; margin-top: -15px"
      />
    </div>

    <loading-dialog v-model="loading" message="Fetching Admin Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { AdminsService } from '../../services/admin-service';
import LoadingDialog from '../../components/LoadingDialog';
import { required, requiredV2, email } from '../../utils/validators';

export default {
  name: 'AdminForm',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    admin_service: new AdminsService(),
    showPassword: '',
    adminScopes: [
      {
        id: 'businessUsers',
        name: 'Business Users',
        children: [
          { id: 'businessUsers:create', name: 'Create Business Users' },
          { id: 'businessUsers:view', name: 'View Business Users' },
          { id: 'businessUsers:edit', name: 'Edit Business Users' },
          { id: 'businessUsers:delete', name: 'Delete Business Users' }
        ]
      },
      {
        id: 'assessmentQuestions',
        name: 'Assessment Questions',
        children: [
          {
            id: 'assessmentQuestions:create',
            name: 'Create Assessment Questions'
          },
          {
            id: 'assessmentQuestions:view',
            name: 'View Assessment Questions'
          },
          {
            id: 'assessmentQuestions:edit',
            name: 'Edit Assessment Questions'
          },
          {
            id: 'assessmentQuestions:delete',
            name: 'Delete Assessment Questions'
          }
        ]
      },
      {
        id: 'assessmentAnswers',
        name: 'Assessment Answers',
        children: [
          {
            id: 'assessmentAnswers:view',
            name: 'View Assessment Answers'
          }
        ]
      },
      {
        id: 'library',
        name: 'Library',
        children: [
          { id: 'library:create', name: 'Create Library' },
          { id: 'library:view', name: 'View Library' },
          { id: 'library:edit', name: 'Edit Library' },
          { id: 'library:delete', name: 'Delete Library' }
        ]
      }
    ],
    // only for edit
    showImageError: false,
    disabled: false,
    loggedInUserId: JSON.parse(localStorage.getItem('user'))._id,
    admin: {
      loginVia: 'Email',
      role: 1,
      name: '',
      email: '',
      password: '',
      scopes: []
    },
    password: {
      old: '',
      new: ''
    }
  }),

  mounted() {
    this.loadAdmin();
  },

  methods: {
    required,
    requiredV2,
    email,

    async loadAdmin() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.admin = await this.admin_service.fetchOne(this.$route.query.id);
      this.loading = false;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Admin');

        try {
          if (!this.password.old) {
            try {
              await this.admin_service.update(this.admin);
              this.$toast.success('Admin updated successfully');
              return true;
            } catch (e) {
              context.reportError({
                title: 'Error while updating Admin',
                description: e.response
                  ? e.response.data.message
                  : 'Something went wrong!'
              });
              return false;
            }
          } else {
            try {
              await this.$axios
                .patch(`/profile/update-password/${this.$route.query.id}`, {
                  oldPassword: this.password.old,
                  newPassword: this.password.new
                })
                .then(() => {
                  this.admin_service.update(this.admin);
                  this.$toast.success('Admin updated successfully');
                });
              return true;
            } catch (e) {
              context.reportError({
                title: 'Error while updating Admin',
                description: e.response
                  ? e.response.data.message
                  : 'Something went wrong!'
              });
              return false;
            }
          }
        } catch (e) {
          context.reportError({
            title: 'Error while updating Admin',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });
          return false;
        }
      } else {
        context.changeLoadingMessage('Creating Admin');
        try {
          await this.admin_service.create(this.admin);
          this.$toast.success('Admin created successfully');
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating Admin',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });

          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
