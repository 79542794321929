<template>
  <div class="main">
    <div class="user-analytics">
      <h2>User Analytics</h2>

      <div class="user-analytics-cards">
        <div class="user-analytic-card" v-if="user && user.role === 0">
          <div
            class="user-analytics-card-icon"
            style="background-color: #ffcc40; box-shadow: 0 0 5px #ffcc40"
          >
            <v-icon dark>mdi-account</v-icon>
          </div>

          <div class="user-analytics-card-info">
            <h3>Total Users</h3>
            <p>
              {{ users.length + businessUsers.length || '0' }}
            </p>
          </div>
        </div>

        <div class="user-analytic-card" v-if="user && user.role === 0">
          <div
            class="user-analytics-card-icon"
            style="background-color: #d32026; box-shadow: 0 0 5px #d32026"
          >
            <v-icon dark>mdi-account</v-icon>
          </div>

          <div class="user-analytics-card-info">
            <h3>Business Users</h3>
            <p>{{ (businessUsers && businessUsers.length) || '0' }}</p>
          </div>
        </div>

        <div class="user-analytic-card" v-if="user && user.role === 1">
          <div
            class="user-analytics-card-icon"
            style="background-color: #ffcc40; box-shadow: 0 0 5px #ffcc40"
          >
            <v-icon dark>mdi-account</v-icon>
          </div>

          <div class="user-analytics-card-info">
            <h3>Registered Users</h3>
            <p>{{ pieChartSeries[0] || '0' }}</p>
          </div>
        </div>

        <div class="user-analytic-card" v-if="user && user.role === 1">
          <div
            class="user-analytics-card-icon"
            style="background-color: #d32026; box-shadow: 0 0 5px #d32026"
          >
            <v-icon dark>mdi-account</v-icon>
          </div>

          <div class="user-analytics-card-info">
            <h3>Active Users</h3>
            <p>{{ pieChartSeries[1] || '0' }}</p>
          </div>
        </div>

        <div class="user-analytic-card">
          <div
            class="user-analytics-card-icon"
            style="background-color: #ec7123; box-shadow: 0 0 5px #ec7123"
          >
            <v-icon dark>mdi-account</v-icon>
          </div>

          <div class="user-analytics-card-info">
            <h3>Assessments Completed</h3>
            <p>1,000</p>
          </div>
        </div>
      </div>
    </div>

    <div class="users-graph">
      <div class="users-line-graph">
        <apexchart
          type="line"
          :options="chartOptions"
          :series="chartSeries"
          height="300px"
        />
      </div>

      <div class="users-pie-graph">
        <apexchart
          type="pie"
          :options="pieChartOptions"
          :series="pieChartSeries"
          height="345px"
        />
      </div>
    </div>

    <data-table
      :loader="loadData"
      :headers="headers"
      title="Business Users"
      :allow-add="userScopes.includes('businessUsers:create')"
      @done="$router.back()"
      @add-new="addNew"
      :edit-handler="userScopes.includes('businessUsers:edit') ? edit : null"
      :view-handler="
        userScopes.includes('businessUsers:view') || (user && user.role == 0)
          ? view
          : null
      "
      :disable-handler="
        userScopes.includes('businessUsers:edit') ? disableHandler : null
      "
      :key="dataTableKey"
    >
      <template #status="{ item }">
        <v-icon :color="item.status === true ? 'green' : 'red'">
          {{ item.status === true ? 'mdi-check' : 'mdi-close' }}
        </v-icon>
      </template>

      <template #createdAt="{ item }">
        {{ formatdate(item.createdAt) }}
      </template>
    </data-table>
  </div>
</template>

<script>
import { UsersService } from '@/services/users-service';
import DataTable from '../../components/DataTable';
import { getUserScopes } from '@/utils/local';
import { getUser } from '@/utils/local';
import dayjs from 'dayjs';
import VueApexCharts from 'vue-apexcharts';

export default {
  components: { DataTable, apexchart: VueApexCharts },

  data: () => ({
    dataTableKey: 0,
    user: getUser(),
    service: new UsersService(),
    userScopes: getUserScopes(),
    users: [],
    businessUsers: [],

    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      {
        text: 'Email',
        value: 'email',
        sortable: true
      },
      {
        text: 'Status',
        value: 'status',
        sortable: true
      },
      {
        text: 'Created By',
        value: 'createdBy',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      }
    ],

    chartOptions: {
      chart: {
        id: 'users-by-month'
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sept',
          'Oct',
          'Nov',
          'Dec'
        ]
      },
      stroke: {
        curve: 'smooth',
        width: 3,
        colors: ['#00A69C']
      },
      theme: {
        mode: 'dark'
      }
    },
    chartSeries: [
      {
        name: 'users',
        data: []
      },
      {
        name: 'business users',
        data: []
      }
    ],

    pieChartOptions: {
      chart: {
        id: 'users-by-status'
      },
      labels: ['Registered Users', 'Active Users'],
      colors: ['#00A69C', '#0076AE'],
      legend: {
        show: true,
        position: 'bottom'
      },
      theme: {
        mode: 'dark'
      }
    },
    pieChartSeries: []
  }),

  async mounted() {
    try {
      this.users = await this.service.fetchAllUsers();
    } catch (e) {
      if (e.response.status === 401) {
        this.$toast.error('Session expired, please login again');

        localStorage.removeItem('auth_token');
        localStorage.removeItem('user');

        this.$router.push('/auth/sign-in');
      }
    }

    // filter users by month
    const usersByMonth = this.businessUsers.reduce((acc, user) => {
      const month = dayjs(user.createdAt).month();
      acc[month] = acc[month] ? acc[month] + 1 : 1;
      return acc;
    }, {});
    this.chartSeries = [
      {
        name: 'users',
        data: [
          usersByMonth[0] || 0,
          usersByMonth[1] || 0,
          usersByMonth[2] || 0,
          usersByMonth[3] || 0,
          usersByMonth[4] || 0,
          usersByMonth[5] || 0,
          usersByMonth[6] || 0,
          usersByMonth[7] || 0,
          usersByMonth[8] || 0,
          usersByMonth[9] || 0,
          usersByMonth[10] || 0,
          usersByMonth[11] || 0
        ]
      }
    ];

    // filter users by status
    const usersByStatus = this.businessUsers.reduce((acc, user) => {
      const status = user.status;
      acc[status] = acc[status] ? acc[status] + 1 : 1;
      return acc;
    }, {});
    this.pieChartSeries = [
      this.businessUsers.length || 0,
      usersByStatus[true] || 0
    ];
  },

  methods: {
    formatdate(date) {
      return dayjs(date).format('DD MMM YYYY - hh:mm A');
    },

    addNew() {
      this.$router.push('/business-user');
    },

    edit(item) {
      this.$router.push(`/business-user?id=${item._id}`);
    },

    view(item) {
      this.$router.push(`/business-user-details?id=${item._id}`);
    },

    async disableHandler(item) {
      try {
        await this.service.disable(item._id, {
          status: item.status === true ? false : true
        });
        let toastMessage =
          item.status === true
            ? 'Business User disabled successfully'
            : 'Business User enabled successfully';
        this.$toast.success(toastMessage);
        this.dataTableKey += 1;
      } catch (e) {
        this.$toast.error('Error disabling Business Admin');
      }
    },

    async loadData() {
      if (this.user.role == 0) {
        let data = await this.service.fetchAllBusinessUsers();

        data.forEach(async (element) => {
          if (element.createdBy) {
            let createdById = element.createdBy;
            let createdByUser = await this.service.fetchOne(createdById);
            element.createdBy = createdByUser.name;
          }
        });

        this.businessUsers = data;

        return data;
      } else {
        let data = await this.service.fetchAllBusinessUsersByAdminId(
          this.user._id
        );

        data.forEach(async (element) => {
          if (element.createdBy) {
            let createdById = element.createdBy;
            let createdByUser = await this.service.fetchOne(createdById);
            element.createdBy = createdByUser.name;
          }
        });

        this.businessUsers = data;

        return data;
      }
    }
  }
};
</script>

<style scoped>
.users-graph {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}
.users-line-graph {
  width: 60%;
  padding: 1rem 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: #424242;
  border-radius: 10px;
}

.users-pie-graph {
  width: 35%;
  padding: 1rem 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: #424242;
  border-radius: 10px;
}
.user-analytics {
  margin: 2rem 0px;
}

.user-analytics h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.user-analytics-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-analytic-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  padding-bottom: 0px;
  width: 30%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 10px;
  background-color: #424242;
  color: white;
}

.user-analytics-card-icon {
  align-items: center;
  border-radius: 10px;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-bottom: 1rem;
  width: 3rem;
}

.user-analytics-card-info {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.user-analytics-card-info h3 {
  font-weight: 500;
}

@media (max-width: 768px) {
  .user-analytics-cards {
    flex-direction: column;
  }

  .user-analytic-card {
    margin-bottom: 1rem;
    width: 80%;
  }

  .users-graph {
    flex-direction: column;
  }

  .users-line-graph {
    width: 100%;
  }

  .users-pie-graph {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .user-analytic-card {
    width: 100%;
  }
}
</style>
