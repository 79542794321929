import { render, staticRenderFns } from "./ProfilePopup.vue?vue&type=template&id=acd9fb82&scoped=true&"
import script from "./ProfilePopup.vue?vue&type=script&lang=js&"
export * from "./ProfilePopup.vue?vue&type=script&lang=js&"
import style0 from "./ProfilePopup.vue?vue&type=style&index=0&id=acd9fb82&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acd9fb82",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VCard,VDivider,VMenu,VSpacer})
