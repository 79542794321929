<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay
        v-if="dataLoading"
        :absolute="dataLoading"
        color="primary"
        opacity="0.55"
      >
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-row no-gutters class="d-flex align-center">
          <v-btn color="primary" outlined @click="$router.back()">
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
          <v-card-title style="color: #020819">Details</v-card-title>
        </v-row>

        <!-- <v-row no-gutters v-if="data.album.thumbnail">
          <v-col cols="12">
            <v-card-text>
              <b>ALbum Cover: </b>
            </v-card-text>
            <v-img
              :src="getFullPath(data.album.thumbnail)"
              width="100%"
              height="400px"
              contain
            ></v-img>
          </v-col>
        </v-row> -->

        <!-- <v-row no-gutters>
          <v-col cols="12">
            <v-card-text> <b>Title : </b>{{ data.album.title }} </v-card-text>
          </v-col>
        </v-row> -->

        <v-row no-gutters>
          <v-col cols="12">
            <v-card-text class="mb-n6">
              <b>Guidelines List: ({{ data.guidelines.resourceType }})</b>
            </v-card-text>
          </v-col>

          <v-col
            cols="12"
            v-for="(item, index) in data.guidelines.items"
            :key="index"
          >
            <v-card-text>
              <v-row no-gutters class="d-flex align-center">
                <v-col>
                  <v-card-text> <b>Title : </b>{{ item.title }} </v-card-text>
                  <v-img
                    :src="getFullPath(item.thumbnail)"
                    width="80px"
                    height="80px"
                    cover
                    style="border-radius: 50%"
                  ></v-img>
                </v-col>

                <v-col>
                  <iframe
                    v-if="data.guidelines.resourceType === 'PDF' || data.guidelines.resourceType === 'VIDEO'"
                    :src="getFullPath(item.link)"
                    height="300px"
                    width="100%"
                    frameborder="0"
                    class="span-2 mb-8"
                  ></iframe>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card-text>
              <b>Created At : </b>{{ formatDate(data.createdAt) }}
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text>
              <b>Updated At : </b>{{ formatDate(data.updatedAt) }}
            </v-card-text>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { LibraryService } from '@/services/library-service';
import { getFullPath } from '../../utils/local';

export default {
  data() {
    return {
      dataLoading: true,
      data: {},
      service: new LibraryService()
    };
  },
  methods: {
    getFullPath,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm A');
    },

    async loadData() {
      try {
        this.dataLoading = true;
        this.data = await this.service.fetchOne(this.$route.query.id);
      } catch (e) {
        console.log(e);
      }

      this.dataLoading = false;
    }
  },
  async mounted() {
    await this.loadData();
  }
};
</script>
