<template>
  <v-card style="background-color: #424242">
    <v-tabs
      v-model="tab"
      dark
      color="primary"
      @change="
        ($event) => {
          $router.push({ path: '/library-all', query: { tab: $event } });

          if ($event == 0) {
            tableTitle = 'Articles, Reports & Resources';
          } else if ($event == 1) {
            tableTitle = 'MyBiQ and STARR guides';
          } else if ($event == 2) {
            tableTitle = 'Videos';
          } else if ($event == 3) {
            tableTitle = 'Links';
          } else if ($event == 4) {
            tableTitle = 'Audios';
          }
        }
      "
    >
      <v-tab> Articles, Reports & Resources </v-tab>
      <v-tab> myBiQ and STARR guides </v-tab>
      <v-tab> Videos </v-tab>
      <v-tab> Links </v-tab>
      <v-tab> Audios </v-tab>
    </v-tabs>

    <data-table
      :loader="loadData"
      :headers="tab === 1 || tab === 3 || tab === 4 ? linksHeader : headers"
      :title="tableTitle"
      :allow-add="true"
      @add-new="addNew"
      @done="$router.back()"
      :edit-handler="edit"
      :delete-handler="deleteItem"
      :view-handler="view"
      :key="dataTableKey"
    >
      <template #author="{ item }" v-if="tab === 0 || tab === 2">
        {{
          (tab === 0 && item.books.author) || (tab === 2 && item.videos.author)
        }}
      </template>

      <template
        #title="{ item }"
        v-if="tab === 0 || tab === 2 || tab === 3 || tab === 4"
      >

        {{
          (tab === 0 && item.books.title) ||
          (tab === 2 && item.videos.title) ||
          (tab === 3 && item.links.title) ||
          (tab === 4 && item.album.title) 
        }}
      </template>

      <template
        #title="{ item }"
        v-if="tab === 1"
      >

        {{ item.guidelines.items[0] && item.guidelines.items[0].title ? item.guidelines.items[0].title + ',...' : 'NA'}}
      </template>

      <template #createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </data-table>
  </v-card>
</template>

<script>
import { LibraryService } from '@/services/library-service.js';
import DataTable from '../../components/DataTable';
import { getUserScopes } from '@/utils/local';
import { getUser, deleteFile } from '@/utils/local';
import dayjs from 'dayjs';

export default {
  components: { DataTable },

  data: () => ({
    tableTitle: '',
    tab: 0,
    dataTableKey: 0,
    service: new LibraryService(),
    userScopes: getUserScopes(),
    user: getUser(),

    headers: [
      {
        text: 'Author',
        value: 'author',
        sortable: true
      },
      {
        text: 'Title',
        value: 'title',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      }
    ],

    linksHeader: [
      {
        text: 'Title',
        value: 'title',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      }
    ]
  }),

  mounted() {
    if (this.$route.query.tab == 0) {
      this.tab = 0;
      this.tableTitle = 'Articles, Reports & Resources';
    } else if (this.$route.query.tab == 1) {
      this.tab = 1;
      this.tableTitle = 'MyBiQ and STARR guides';
    } else if (this.$route.query.tab == 2) {
      this.tab = 2;
      this.tableTitle = 'Videos';
    } else if (this.$route.query.tab == 3) {
      this.tab = 3;
      this.tableTitle = 'Links';
    } else if (this.$route.query.tab == 4) {
      this.tab = 4;
      this.tableTitle = 'Audios';
    } else {
      this.tab = 0;
      this.tableTitle = 'Articles, Reports and Resources';
    }
  },

  watch: {
    tab() {
      this.dataTableKey += 1;
    }
  },

  methods: {
    deleteFile,

    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY - hh:mm A');
    },

    addNew() {
      let route;

      if (this.tab == 0) {
        route = '/library/book';
      } else if (this.tab == 1) {
        route = '/library/guidelines';
      } else if (this.tab == 2) {
        route = '/library/video';
      } else if (this.tab == 3) {
        route = '/library/link';
      } else if (this.tab == 4) {
        route = '/library/audio';
      }

      this.$router.push(route);
    },

    edit(item) {
      let route;

      if (this.tab == 0) {
        route = `/library/book?id=${item._id}`;
      } else if (this.tab == 1) {
        route = `/library/guidelines?id=${item._id}`;
      } else if (this.tab == 2) {
        route = `/library/video?id=${item._id}`;
      } else if (this.tab == 3) {
        route = `/library/link?id=${item._id}`;
      } else if (this.tab == 4) {
        route = `/library/audio?id=${item._id}`;
      } else {
        route = '/library-all';
      }

      this.$router.push(route);
    },

    async deleteItem(item) {
      if (item.itemType === 'BOOK') {
        if (item.books && item.books.thumbnail) {
          await this.deleteFile(item.books.thumbnail);
        }

        if (item.books && item.books.link) {
          await this.deleteFile(item.books.link);
        }
      }

      if (item.itemType === 'VIDEO') {
        if (item.videos && item.videos.thumbnail) {
          await this.deleteFile(item.videos.thumbnail);
        }

        if (item.videos && item.videos.link) {
          await this.deleteFile(item.videos.link);
        }
      }

      if (item.itemType === 'AUDIO') {
        if (item.album && item.album.thumbnail) {
          await this.deleteFile(item.album.thumbnail);
        }

        if (item.album && item.album.link) {
          await this.deleteFile(item.album.link);
        }

        if (item.album && item.album.audios.length > 0) {
          for (let i = 0; i < item.album.audios.length; i++) {
            if (item.album.audios[i].thumbnail) {
              await this.deleteFile(item.album.audios[i].thumbnail);
            }

            if (item.album.audios[i].link) {
              await this.deleteFile(item.album.audios[i].link);
            }
          }
        }
      }

      if (item.itemType === 'GUIDELINES') {
        if (item.guidelines && item.guidelines.items.length > 0) {
          for (let i = 0; i < item.guidelines.items.length; i++) {
            let gItem = item.guidelines.items[i];
            if (gItem.thumbnail) {
              await this.deleteFile(gItem.thumbnail);
            }

            if (gItem.link) {
              await this.deleteFile(gItem.link);
            }
          }
        }
      }

      await this.service.delete(item._id);
    },

    view(item) {
      let route;

      if (this.tab == 0) {
        route = `/library-book-details?id=${item._id}`;
      } else if (this.tab == 1) {
        route = `/library-guidelines-details?id=${item._id}`;
      } else if (this.tab == 2) {
        route = `/library-video-details?id=${item._id}`;
      } else if (this.tab == 3) {
        route = `/library-link-details?id=${item._id}`;
      } else if (this.tab == 4) {
        route = `/library-audio-details?id=${item._id}`;
      } else {
        route = '/library-all';
      }

      this.$router.push(route);
    },

    async loadData() {
      if (this.tab == 0) {
        let data = await this.service.fetchAll('BOOK', this.user._id);
        data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        return data;
      } else if (this.tab == 1) {
        let data = await this.service.fetchAll('GUIDELINES', this.user._id);
        data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        return data;
      } else if (this.tab == 2) {
        let data = await this.service.fetchAll('VIDEO', this.user._id);
        data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        return data;
      } else if (this.tab == 3) {
        let data = await this.service.fetchAll('LINK', this.user._id);
        data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        return data;
      } else if (this.tab == 4) {
        let data = await this.service.fetchAll('AUDIO', this.user._id);
        data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        return data;
      }
    }
  }
};
</script>
