import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import axios from 'axios';
import './assets/style.sass';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

Vue.use(Toast, {
  position: 'bottom-right'
});

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

// BaseUrl
axios.defaults.baseURL = 'https://api.mybiq.com/';
// axios.defaults.baseURL = 'http://192.168.1.4:4000/';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      config.headers.common['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

new Vue({
  vuetify,
  router,
  render: (h) => h(App)
}).$mount('#app');
