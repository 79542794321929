<template>
  <data-table
    :loader="loadData"
    :headers="headers"
    title="Super Admin"
    @done="$router.back()"
    :edit-handler="user && user.role === 0 ? edit : null"
    :deleteHandler="null"
  >
    <template #createdAt="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
  </data-table>
</template>

<script>
import { AdminsService } from '@/services/admin-service';
import DataTable from '../../components/DataTable';
import { getUserScopes } from '@/utils/local';
import { getUser } from '@/utils/local';
import dayjs from 'dayjs';

export default {
  components: { DataTable },

  data: () => ({
    admin_service: new AdminsService(),
    userScopes: getUserScopes(),
    user: getUser(),

    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      {
        text: 'Email',
        value: 'email',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      }
    ]
  }),

  methods: {
    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY - hh:mm A');
    },

    // addNew() {
    //   this.$router.push('/admin');
    // },

    edit(item) {
      this.$router.push(`/super-admin?id=${item._id}`);
    },

    // view(item) {
    //   this.$router.push(`/super-admin-details?id=${item._id}`);
    // },

    // async deleteAdmin(item) {
    //   await this.admin_service.delete(item);
    // },

    async loadData() {
      let admin = await this.admin_service.fetchSuperAdmins();
      return admin;
    }
  }
};
</script>
