<template>
  <data-table
    :loader="loadData"
    :headers="headers"
    title="Business Users"
    :allow-add="userScopes.includes('businessUsers:create')"
    @done="$router.back()"
    @add-new="addNew"
    :edit-handler="userScopes.includes('businessUsers:edit') ? edit : null"
    :view-handler="
      userScopes.includes('businessUsers:view') || (user && user.role == 0)
        ? view
        : null
    "
    :disable-handler="
      userScopes.includes('businessUsers:edit') ? disableHandler : null
    "
    :key="dataTableKey"
  >
    <template #status="{ item }">
      <v-icon :color="item.status === true ? 'green' : 'red'">
        {{ item.status === true ? 'mdi-check' : 'mdi-close' }}
      </v-icon>
    </template>

    <template #createdAt="{ item }">
      {{ formatdate(item.createdAt) }}
    </template>
  </data-table>
</template>

<script>
import { UsersService } from '@/services/users-service';
import DataTable from '../../components/DataTable';
import { getUserScopes } from '@/utils/local';
import { getUser } from '@/utils/local';
import dayjs from 'dayjs';

export default {
  components: { DataTable },

  data: () => ({
    dataTableKey: 0,
    user: getUser(),
    service: new UsersService(),
    userScopes: getUserScopes(),

    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      {
        text: 'Email',
        value: 'email',
        sortable: true
      },
      {
        text: 'Status',
        value: 'status',
        sortable: true
      },
      {
        text: 'Created By',
        value: 'createdBy',
        sortable: true
      },
      {
        text: 'Created At',
        value: 'createdAt',
        sortable: true
      }
    ]
  }),

  methods: {
    formatdate(date) {
      return dayjs(date).format('DD MMM YYYY - hh:mm A');
    },

    addNew() {
      this.$router.push('/business-user');
    },

    edit(item) {
      this.$router.push(`/business-user?id=${item._id}`);
    },

    view(item) {
      this.$router.push(`/business-user-details?id=${item._id}`);
    },

    async disableHandler(item) {
      try {
        await this.service.disable(item._id, {
          status: item.status === true ? false : true
        });
        let toastMessage =
          item.status === true
            ? 'Business User disabled successfully'
            : 'Business User enabled successfully';
        this.$toast.success(toastMessage);
        this.dataTableKey += 1;
      } catch (e) {
        this.$toast.error('Error disabling Business Admin');
      }
    },

    async loadData() {
      if (this.user.role == 0) {
        let data = await this.service.fetchAllBusinessUsers();

        data.forEach(async (element) => {
          if (element.createdBy) {
            let createdById = element.createdBy;
            let createdByUser = await this.service.fetchOne(createdById);
            element.createdBy = createdByUser.name;
          }
        });

        return data;
      } else {
        let data = await this.service.fetchAllBusinessUsersByAdminId(
          this.user._id
        );

        data.forEach(async (element) => {
          if (element.createdBy) {
            let createdById = element.createdBy;
            let createdByUser = await this.service.fetchOne(createdById);
            element.createdBy = createdByUser.name;
          }
        });

        return data;
      }
    }
  }
};
</script>
