<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay
        v-if="dataLoading"
        :absolute="dataLoading"
        color="primary"
        opacity="0.55"
      >
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-row no-gutters class="d-flex align-center">
          <v-btn color="primary" outlined @click="$router.back()">
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
          <v-card-title style="color: #020819">Team Details</v-card-title>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card-text> <b>Title : </b>{{ team.title }} </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text>
              <b>Team Lead : </b>
              {{
                team.members.filter((member) => member.isTeamLead)[0].info.name
              }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card-text>
              <b>Members : </b>
              <v-card-text v-for="member in team.members" :key="member.title">
                {{ member.info.name }}
              </v-card-text>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text>
              <b>Created At : </b>{{ formatDate(team.createdAt) }}
            </v-card-text>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { TeamsService } from '@/services/teams-service';
import { getFullPath } from '../../utils/local';

export default {
  data() {
    return {
      dataLoading: true,
      team: {},
      service: new TeamsService()
    };
  },
  methods: {
    getFullPath,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm A');
    },

    async loadTeam() {
      try {
        this.dataLoading = true;
        this.team = await this.service.fetchOne(this.$route.query.id);
      } catch (e) {
        console.log(e);
      }
      this.dataLoading = false;
    }
  },
  async mounted() {
    await this.loadTeam();
  }
};
</script>
