<template>
  <SimpleForm
    :onSubmit="submit"
    @done="
      () => {
        this.loadUser();
        this.$toast.success('Profile Updated Successfully');
      }
    "
  >
    <p class="span-2 form__title">Edit Profile</p>

    <div style="margin: 0 auto" class="span-2">
      <v-img
        v-if="user.image || imageViewUrl"
        :src="imageViewUrl ? imageViewUrl : user.image"
        height="300"
        width="300"
        accept="image/*"
        cover
        class="span-2 mb-10"
        style="border-radius: 50%"
        @load="loading = false"
      ></v-img>
    </div>

    <v-file-input
      v-model="image"
      class="span-2"
      :label="user.image ? 'Change Image' : 'Upload Image'"
      accept="image/*"
      outlined
    />

    <v-text-field
      v-model="user.name"
      :rules="[required('Name must be provided'), requiredV2()]"
      class="span-2"
      label="Name"
      outlined
    />

    <v-text-field
      v-if="user.role === 1"
      v-model="user.businessName"
      class="span-2"
      label="Business Name"
      outlined
    />

    <v-text-field
      v-if="user.role === 1"
      v-model="user.address"
      class="span-2"
      label="Address"
      outlined
    />

    <v-text-field
      v-if="user.role === 1"
      v-model="user.contactPerson"
      class="span-2"
      label="Contact Person"
      outlined
    />

    <v-text-field
      v-if="user.role === 1"
      v-model="user.primaryContact"
      class="span-2"
      label="Primary Contact"
      outlined
    />

    <p class="span-2 form__title">Change Password</p>

    <v-text-field
      v-model="oldPassword"
      :rules="
        newPassword
          ? [required('Old Password must be provided'), requiredV2()]
          : []
      "
      :append-icon="oldShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="oldShowPassword ? 'text' : 'password'"
      @click:append="oldShowPassword = !oldShowPassword"
      class="span-2"
      label="Old Password"
      outlined
    />

    <v-text-field
      v-model="newPassword"
      :rules="
        oldPassword
          ? [required('New Password must be provided'), requiredV2()]
          : []
      "
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPassword ? 'text' : 'password'"
      @click:append="showPassword = !showPassword"
      class="span-2"
      label="New Password"
      outlined
    />

    <v-text-field
      v-model="confirmPassword"
      :rules="
        newPassword
          ? [
              required('Confirm Password must be provided'),
              requiredV2(),
              (v) => v === newPassword || 'Password does not match'
            ]
          : []
      "
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPassword ? 'text' : 'password'"
      @click:append="showPassword = !showPassword"
      class="span-2"
      label="Confirm Password"
      outlined
    />

    <loading-dialog v-model="loading" message="Fetching Profile Data..." />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { AdminsService } from '@/services/admin-service';
import LoadingDialog from '../../components/LoadingDialog';
import { required, requiredV2 } from '@/utils/validators';
import { getUser } from '../../utils/local';
import { uploadFile, getFullPath, deleteFile } from '../../utils/local';

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    loading: false,
    service: new AdminsService(),
    localUser: getUser(),

    image: null,
    oldImage: null,

    oldShowPassword: false,
    showPassword: false,
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',

    user: {
      password: '',
      image: '',
      name: '',
      businessName: '',
      address: '',
      contactPerson: '',
      primaryContact: ''
    }
  }),

  mounted() {
    this.loadUser();
  },

  computed: {
    imageViewUrl() {
      return this.image ? URL.createObjectURL(this.image) : null;
    }
  },

  methods: {
    required,
    requiredV2,
    uploadFile,
    getFullPath,
    deleteFile,

    async loadUser() {
      this.loading = true;
      this.user = await this.service.fetchOne(this.localUser._id);
      if (this.user.image) {
        let imageURL = this.getFullPath(this.user.image);
        this.oldImage = this.user.image;
        this.user.image = imageURL;
      } else {
        this.loading = false;
      }

      this.loading = false;
    },
    async submit(context) {
      context.changeLoadingMessage('Updating Profile');

      try {
        if (this.image) {
          const imageResponse = await this.uploadFile(this.image);
          if (this.oldImage) {
            await this.deleteFile(this.oldImage);
          }
          this.user.image = imageResponse.data.name;
        } else if (this.oldImage) {
          this.user.image = this.oldImage;
        } else {
          this.user.image = '';
        }

        await this.service.update(this.user);

        if (this.newPassword) {
          await this.service.changePassword({
            person_id: this.user._id,
            oldPassword: this.oldPassword,
            newPassword: this.newPassword
          });
        }

        localStorage.setItem('user', JSON.stringify(this.user));
        window.location.reload();
        return true;
      } catch (e) {
        context.reportError({
          title: 'Error while updating profile',
          description: e.response
            ? e.response.data.message
            : 'Something went wrong!'
        });
        return false;
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
