import View from './View';
import Form from './Form.vue';
import Show from './Show.vue';
import { getUser } from '@/utils/local';

export const stopRoutes = [
  {
    name: 'stops',
    path: '/stops',
    component: View
  },
  getUser()?.role === 0
    ? {
        name: 'NewStop',
        path: '/stop',
        component: Form
      }
    : {
        path: '/stop',
        redirect: '/no-permission'
      },
  {
    name: 'StopDetails',
    path: '/stop-details',
    component: Show
  }
];

export const stopRouter = stopRoutes.filter(function (x) {
  return x !== null;
});
