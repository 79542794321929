<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay
        v-if="dataLoading"
        :absolute="dataLoading"
        color="primary"
        opacity="0.55"
      >
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-row no-gutters class="d-flex align-center">
          <v-btn color="primary" outlined @click="$router.back()">
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
          <v-card-title style="color: #020819"
            >Business User Details</v-card-title
          >
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card-text> <b>Name : </b>{{ user.name }} </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text> <b>Email : </b>{{ user.email }} </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6" v-if="user && user.email">
            <v-card-text>
              <b>Position At Work : </b>{{ user.positionAtWork }}
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6" v-if="user && user.createdBy">
            <v-card-text>
              <b>Created By : </b>{{ user.createdBy }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card-text>
              <b>User Setup Link : </b>
              <a
                :href="`https://app.mybiq.com/account-setup/${user._id}`"
                target="_blank"
                >{{ `https://app.mybiq.com/account-setup/${user._id}` }}</a
              >
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text>
              <b>Created At : </b>{{ formatDate(user.createdAt) }}
            </v-card-text>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { UsersService } from '../../services/users-service';
import { getFullPath } from '../../utils/local';

export default {
  data() {
    return {
      dataLoading: true,
      user: {},
      service: new UsersService()
    };
  },
  methods: {
    getFullPath,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm A');
    },

    async loadAgency() {
      try {
        this.dataLoading = true;
        this.user = await this.service.fetchOne(this.$route.query.id);

        this.user.createdBy = await this.service.fetchOne(this.user.createdBy);
        this.user.createdBy = this.user.createdBy.name;
      } catch (e) {
        console.log(e);
      }
      this.dataLoading = false;
    }
  },
  async mounted() {
    await this.loadAgency();
  }
};
</script>
