import axios from 'axios';

export class LibraryService {
  async fetchAll(itemType, adminId) {
    if (adminId)
      return (
        await axios.get('/library?itemType=' + itemType + '&adminId=' + adminId)
      ).data;
    return (await axios.get('/library?itemType=' + itemType)).data;
  }

  async addAudioToLibrary(data, libId) {
    return (await axios.post('/Audios?libId=' + libId, data)).data;
  }

  async fetchOne(id) {
    return (await axios.get('/library/' + id)).data;
  }

  async create(data) {
    return (await axios.post('/library', data)).data;
  }

  async update(data) {
    return (await axios.patch('/library/' + data._id, data)).data;
  }

  async delete(id) {
    return (await axios.delete('/library/' + id)).data;
  }

  async deleteAudioFromLibrary(id, libId) {
    return (await axios.delete('Audios/' + id + '?libId=' + libId)).data;
  }
}
