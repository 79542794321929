<template>
  <div style="background-color: #424242; height: 100%; color: white">
    <v-app-bar app color="#363636" dark elevate-on-scroll>
      <v-app-bar-nav-icon @click="drawer = !drawer" />

      <v-card-title class="name-title"
        >Hello {{ (user && user.name) || '' }} 👋🏼</v-card-title
      >

      <v-spacer />

      <profile-popup />
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>

    <v-navigation-drawer v-model="drawer" app dark style="color: white">
      <img
        v-if="user.image && user.role === 1"
        :src="getFullPath(user.image)"
        alt="logo"
        class="logo"
        width="100"
        height="100"
        style="border-radius: 50%"
      />
      <img
        v-else-if="user.role === 1 && !user.image"
        src="../assets/user.jpg"
        alt="logo"
        class="logo"
        style="border-radius: 50%"
        width="100"
      />
      <img
        v-else
        src="../assets/logo.png"
        alt="logo"
        class="logo"
        height="60"
      />
      <v-card-text class="caption mb-n4">Menu</v-card-text>
      <v-list nav>
        <template v-for="(route, key) in routes">
          <v-list-item
            v-if="route.isVisible"
            :key="key"
            dense
            :to="route.to"
            class="my-2"
          >
            <v-list-item-icon>
              <v-icon v-text="route.icon" />
            </v-list-item-icon>
            <v-list-item-content class="ml-n2">
              <v-list-item-title v-text="route.title" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <v-card-text class="caption mt-6 mb-n4">More</v-card-text>
      <v-list nav>
        <template v-for="(route, key) in moreRoutes">
          <v-list-item
            v-if="route.isVisible"
            :key="key + 'more'"
            dense
            @click="route.title === 'Logout' ? logout() : null"
            :to="route.title !== 'Logout' ? route.to : null"
            class="my-2"
          >
            <v-list-item-icon>
              <v-icon v-text="route.icon" />
            </v-list-item-icon>
            <v-list-item-content class="ml-n2">
              <v-list-item-title v-text="route.title" />
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-else-if="route.isDivider"
            :key="route.title"
            style="margin: 10px 20px"
          />
        </template>
      </v-list>

      <img
        v-if="user.role === 1"
        src="../assets/logo.png"
        alt="logo"
        class="logo"
        height="60"
        style="margin: 0 auto; margin-top: 30px; margin-bottom: 20px"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import ProfilePopup from './ProfilePopup';
import { required } from '@/utils/validators';
import { getUserScopes } from '../utils/local';
import { getUser, getFullPath } from '../utils/local';

export default {
  name: 'Dashboard',
  components: {
    ProfilePopup
  },
  data: () => ({
    drawer: true,
    user: getUser(),
    userScopes: getUserScopes(),
    developMode: false,
    developModeDialog: false,
    message: null,
    developModeData: null,
    loading: false
  }),
  mounted() {
    this.user = getUser();
  },
  methods: {
    required,
    getFullPath,

    getActiveList() {
      const links = document.querySelectorAll('nav a');
      links.forEach((el) => {
        el.classList.remove('v-list-item--active');
      });
    },

    logout() {
      if (confirm('Are you sure you want to logout?')) {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('user');

        this.$router.push('/auth/sign-in');
      }
    }
  },
  computed: {
    routes() {
      // TODO Need to Change isVisible back to using Scopes
      return [
        {
          to: '/',
          title: 'Dashboard',
          icon: 'mdi-home',
          isVisible: true
        },
        {
          to: '/super-admins',
          title: 'Super Admin',
          icon: 'mdi-account',
          isVisible: this.user && this.user.role === 0 ? true : false
        },
        {
          to: '/business-admins',
          title: 'Business Admins',
          icon: 'mdi-account-group',
          isVisible: this.user && this.user.role === 0 ? true : false
        },
        {
          to: '/users',
          title: 'Users',
          icon: 'mdi-account-group',
          isVisible: this.user && this.user.role === 0 ? true : false
        },
        {
          to: '/business-users',
          title: 'Business Users',
          icon: 'mdi-account-group',
          isVisible:
            (this.user && this.user.role === 0) ||
            this.user.scopes.includes('businessUsers:view')
              ? true
              : false
        },
        {
          to: '/assessment-questions',
          title: 'Assessment Questions',
          icon: 'mdi-chat-question',
          isVisible:
            this.user && this.user.role === 0
              ? true
              : false || this.user.scopes.includes('assessmentQuestions:view')
        },
        {
          to: '/assessment-answers',
          title: 'Assessment Answers',
          icon: 'mdi-forum',
          isVisible:
            this.user && this.user.role === 0
              ? true
              : false || this.user.scopes.includes('assessmentAnswers:view')
        },
        {
          to: '/teams',
          title: 'Teams',
          icon: 'mdi-account-group',
          isVisible: this.user && this.user.role === 1 ? true : false
        },
        // {
        //   to: '/journals',
        //   title: 'Journals',
        //   icon: 'mdi-book-open-page-variant',
        //   isVisible: true
        // },
        {
          to: '/library-all?tab=0',
          title: 'Library',
          icon: 'mdi-library',
          isVisible:
            this.user && this.user.role === 0
              ? true
              : false || this.user.scopes.includes('library:view')
        },
        {
          to: '/stops',
          title: 'Stops',
          icon: 'mdi-stop',
          isVisible: true
        },
        {
          to: '/term-hubs?tab=0',
          title: 'Term Hubs',
          icon: 'mdi-library',
          isVisible: this.user && this.user.role === 0 ? true : false
        }
        // {
        //   to: '/reviews',
        //   title: 'Reviews',
        //   icon: 'mdi-star',
        //   isVisible: true
        // }

        // {
        //   to: '/coach-requests',
        //   title: 'Coach Requests',
        //   icon: 'mdi-account',
        //   isVisible: true
        // }

        // { isDivider: true },
      ];
    },

    moreRoutes() {
      return [
        {
          to: '/setting',
          title: 'Settings',
          icon: 'mdi-cog',
          isVisible: true
        },
        {
          to: '/admin-configuration',
          title: 'Admin Configuration',
          icon: 'mdi-cog',
          isVisible:
            (this.user && this.user.role === 0) || this.user.role === 1
              ? true
              : false
        },
        {
          to: '/logout',
          title: 'Logout',
          icon: 'mdi-logout',
          isVisible: true
        }
      ];
    }
  }
};
</script>

<style scoped>
.logo {
  margin: 20px auto;
  display: block;
}

.v-list-item__title {
  font-size: 14px !important;
}

.v-list-item--active {
  background: #424242 !important;
  color: inherit !important;
}
</style>
