import TeamView from './TeamView';
import TeamShow from './TeamShow';
import TeamForm from './TeamForm.vue';
import { getUser } from '../../utils/local';

export const teamsRoutes = [
  getUser()?.role === 1
    ? {
        name: 'Team',
        path: '/teams',
        component: TeamView
      }
    : {
        path: '/teams',
        redirect: '/no-permission'
      },
  getUser()?.role === 1
    ? {
        name: 'NewTeam',
        path: '/team',
        component: TeamForm
      }
    : {
        path: '/team',
        redirect: '/no-permission'
      },
  getUser()?.role === 1
    ? {
        name: 'TeamDetails',
        path: '/team-details',
        component: TeamShow
      }
    : {
        path: '/team-details',
        redirect: '/no-permission'
      }
];

export const teamsRouter = teamsRoutes.filter(function (x) {
  return x !== null;
});
