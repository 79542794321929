var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleForm',{attrs:{"onSubmit":_vm.submit,"disabled":!_vm.adminConfiguration.welcomeMessage &&
    !_vm.adminConfiguration.homeDialogTitle &&
    !_vm.adminConfiguration.homeDialogMessage},on:{"done":function () {
      this$1.loadData();
      this$1.$toast.success('Admin Configuration Updated Successfully');
    }}},[_c('p',{staticClass:"span-2 form__title"},[_vm._v("Edit Admin Configuration")]),_c('v-text-field',{staticClass:"span-2",attrs:{"label":"Welcome Message","outlined":""},model:{value:(_vm.adminConfiguration.welcomeMessage),callback:function ($$v) {_vm.$set(_vm.adminConfiguration, "welcomeMessage", $$v)},expression:"adminConfiguration.welcomeMessage"}}),_c('v-text-field',{staticClass:"span-2",attrs:{"label":"Home Dialog Title","outlined":""},model:{value:(_vm.adminConfiguration.homeDialogTitle),callback:function ($$v) {_vm.$set(_vm.adminConfiguration, "homeDialogTitle", $$v)},expression:"adminConfiguration.homeDialogTitle"}}),_c('v-textarea',{staticClass:"span-2",attrs:{"label":"Home Dialog Message","outlined":"","rows":"3"},model:{value:(_vm.adminConfiguration.homeDialogMessage),callback:function ($$v) {_vm.$set(_vm.adminConfiguration, "homeDialogMessage", $$v)},expression:"adminConfiguration.homeDialogMessage"}}),_c('loading-dialog',{attrs:{"message":"Fetching Admin Configuration..."},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }