<template>
  <SimpleForm
    :is-edit="isEdit"
    :disabled="disabled"
    :onSubmit="submit"
    @done="$router.back()"
  >
    <v-row no-gutters class="d-flex span-2">
      <v-btn color="primary" class="mr-16" outlined @click="$router.back()">
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <p class="form__title">
        {{ isEdit ? 'Update Business User' : 'Add New Business User' }}
      </p>
    </v-row>

    <v-text-field
      v-model="user.name"
      :rules="[required('Name must be provided'), requiredV2()]"
      class="span-2"
      label="Name"
      outlined
    />

    <v-text-field
      v-if="!isEdit"
      v-model="user.email"
      :rules="[
        required('Email must be provided'),
        email('Email must be valid'),
        requiredV2()
      ]"
      class="span-2"
      label="Email"
      type="email"
      outlined
    />

    <v-text-field
      v-model="user.positionAtWork"
      :rules="[required('Position must be provided'), requiredV2()]"
      class="span-2"
      label="Position at Work"
      outlined
    />
    <v-dialog v-model="userCreatedDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline"
          >Business User Created successfully!</v-card-title
        >
        <v-card-text>
          <p>Share this link with the user to sign into the app.</p>
          <p>
            <a
              v-if="newCreateUser"
              :href="`https://app.mybiq.com/account-setup/${newCreateUser._id}`"
            >
              {{ `https://app.mybiq.com/account-setup/${newCreateUser._id}` }}
            </a>
            <v-icon
              class="ml-2"
              color="primary"
              @click="
                copyToClipboard(
                  `https://app.mybiq.com/account-setup/${newCreateUser._id}`
                )
              "
            >
              mdi-content-copy
            </v-icon>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" elevation="0" @click="closeUserCreatedDialog"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loading" message="Fetching Business User Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { UsersService } from '@/services/users-service';
import LoadingDialog from '../../components/LoadingDialog';
import { required, requiredV2, email } from '@/utils/validators';
import { getUser } from '@/utils/local';

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    service: new UsersService(),
    localUser: getUser(),
    userCreatedDialog: false,

    // only for edit
    disabled: false,
    newCreateUser: null,
    user: {
      role: 2,
      loginVia: 'Email',
      name: '',
      email: '',
      positionAtWork: '',
      isBusinessUser: true,
      createdBy: JSON.parse(localStorage.getItem('user'))._id
    }
  }),

  mounted() {
    this.loadUser();
  },

  methods: {
    required,
    requiredV2,
    email,

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$toast.success('Link copied to clipboard');
    },

    closeUserCreatedDialog() {
      this.userCreatedDialog = false;
      this.$router.push('/business-users');
    },

    async loadUser() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.user = await this.service.fetchOne(this.$route.query.id);
      this.loading = false;
    },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Business User');

        try {
          this.service.update(this.user);
          return true;
        } catch (e) {
          context.reportError({
            title: 'Error while updating Business User',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });
          return false;
        }
      } else {
        context.changeLoadingMessage('Creating Business User');
        try {
          this.newCreateUser = await this.service.create(this.user);

          this.userCreatedDialog = true;
        } catch (e) {
          context.reportError({
            title: 'Error while creating Business User',
            description: e.response
              ? e.response.data.message
              : 'Something went wrong!'
          });

          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
