<template>
  <data-table
    :loader="loadData"
    :headers="headers"
    title="Assessment Answers"
    @done="$router.back()"
    :view-handler="user && user.role == 0 ? view : null"
  >
    <template #user="{ item }">
      {{ item.user && item.user.name }}
    </template>

    <template #assessmentType="{ item }">
      {{
        item.assessmentType == 1
          ? 'Behavioral Intelligence'
          : item.assessmentType == 2
          ? 'Wheel Of Life'
          : 'Additional Assessment'
      }}
    </template>

    <template #createdAt="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
  </data-table>
</template>

<script>
import { AssessmentAnswersService } from '@/services/assessment-answers-service';
import DataTable from '../../components/DataTable';
import { getUserScopes } from '@/utils/local';
import { getUser } from '@/utils/local';
import dayjs from 'dayjs';

export default {
  components: { DataTable },

  data: () => ({
    dataTableKey: 0,
    user: getUser(),
    service: new AssessmentAnswersService(),
    userScopes: getUserScopes(),
    itemPerPage: 50,

    headers: [
      {
        text: 'User Name',
        value: 'user',
        sortable: true
      },
      {
        text: 'Assessment Type',
        value: 'assessmentType',
        sortable: true
      }
    ]
  }),

  methods: {
    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY - hh:mm A');
    },

    view(item) {
      this.$router.push(`/assessment-answer-details?id=${item._id}`);
    },

    async loadData() {
      if (this.user.role == 0) {
        let data = await this.service.fetchAll();
        data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        return data;
      } else {
        let data = await this.service.fetchAllByUserId(this.user._id);
        data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        return data;
      }
    }
  }
};
</script>
