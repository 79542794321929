<template>
  <SimpleForm
    :onSubmit="submit"
    @done="
      () => {
        this.loadData();
        this.$toast.success('Admin Configuration Updated Successfully');
      }
    "
    :disabled="
      !adminConfiguration.welcomeMessage &&
      !adminConfiguration.homeDialogTitle &&
      !adminConfiguration.homeDialogMessage
    "
  >
    <p class="span-2 form__title">Edit Admin Configuration</p>

    <v-text-field
      v-model="adminConfiguration.welcomeMessage"
      class="span-2"
      label="Welcome Message"
      outlined
    />

    <v-text-field
      v-model="adminConfiguration.homeDialogTitle"
      class="span-2"
      label="Home Dialog Title"
      outlined
    />

    <v-textarea
      v-model="adminConfiguration.homeDialogMessage"
      class="span-2"
      label="Home Dialog Message"
      outlined
      rows="3"
    ></v-textarea>

    <loading-dialog
      v-model="loading"
      message="Fetching Admin Configuration..."
    />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import { AdminConfigurationService } from '@/services/admin-configuration-service';
import LoadingDialog from '../../components/LoadingDialog';
import { getUser } from '../../utils/local';

export default {
  name: 'Form',
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    loading: false,
    service: new AdminConfigurationService(),
    localUser: getUser(),

    adminConfiguration: {
      welcomeMessage: '',
      homeDialogTitle: '',
      homeDialogMessage: ''
    }
  }),

  mounted() {
    this.loadData();
  },

  methods: {
    async loadData() {
      this.loading = true;
      let adminConfiguration = await this.service.fetchByAdminId(
        this.localUser._id
      );
      if (adminConfiguration.length > 0) {
        this.adminConfiguration = adminConfiguration[0];
      }
      this.adminConfiguration.adminId = this.localUser._id;
      this.loading = false;
    },
    async submit(context) {
      context.changeLoadingMessage('Updating Admin Configuration');

      try {
        await this.service.create(this.adminConfiguration);

        return true;
      } catch (e) {
        context.reportError({
          title: 'Error while updating admin configuration',
          description: e.response
            ? e.response.data.message
            : 'Something went wrong!'
        });
        return false;
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
