import axios from 'axios';

export class AdminsService {
  async fetchSuperAdmins() {
    return (await axios.get('/person?role=0')).data;
  }

  async fetchBusinessAdmins() {
    return (await axios.get('/person?role=1')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/person/' + id)).data;
  }

  async create(admin) {
    return (await axios.post('/auth/sign-up', admin)).data;
  }

  async update(admin) {
    return (await axios.patch('/person/' + admin._id, admin)).data;
  }

  async delete(admin) {
    return (await axios.delete('/person/' + admin._id)).data;
  }

  async disable(id, status) {
    return (await axios.patch('/person/' + id + '/update-status', status)).data;
  }

  changePassword(admin) {
    return axios.patch('/person/change-password', admin);
  }
}
