<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay
        v-if="dataLoading"
        :absolute="dataLoading"
        color="primary"
        opacity="0.55"
      >
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-row no-gutters class="d-flex align-center">
          <v-btn color="primary" outlined @click="$router.back()">
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
          <v-card-title style="color: #020819"
            >Assessment Answer Details</v-card-title
          >
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card-text>
              <b>User Name : </b>{{ answer.user.name }}
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text>
              <b>Type : </b>{{ answer.assessmentType }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <v-card-text>
              <b>Answers : </b>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                  v-for="(answer, index) in answer.answers"
                  :key="index"
                  style="
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    margin: 10px 0px;
                  "
                >
                  <v-card-text>
                    <b>Question : </b>{{ answer.question.question }}
                  </v-card-text>
                  <v-card-text>
                    <b>Answer : </b>{{ answer.answer }}
                  </v-card-text>
                  <v-card-text>
                    <b>Statement : </b>{{ answer.statement }}
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <v-card-text>
              <b>Results:</b>

              <v-row no-gutters>
                <v-col
                  style="
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    margin: 10px 0px;
                  "
                >
                  <v-col class="d-flex">
                    <v-card-text>
                      <b>Stop : </b>{{ answer.result.stop + ' %' }}
                    </v-card-text>

                    <v-card-text>
                      <b>Think : </b>{{ answer.result.think + ' %' }}
                    </v-card-text>
                  </v-col>

                  <v-col class="d-flex mt-n6">
                    <v-card-text>
                      <b>Assess : </b>{{ answer.result.assess + ' %' }}
                    </v-card-text>

                    <v-card-text>
                      <b>Respond : </b>{{ answer.result.respond + ' %' }}
                    </v-card-text>
                  </v-col>

                  <v-col class="d-flex mt-n6">
                    <v-card-text>
                      <b>Review : </b>{{ answer.result.review + ' %' }}
                    </v-card-text>

                    <v-card-text>
                      <b>General : </b>{{ answer.result.general + ' %' }}
                    </v-card-text>
                  </v-col>

                  <v-col class="d-flex mt-n6">
                    <v-card-text>
                      <b>Global BIQ : </b>{{ answer.result.globalBIQ + ' %' }}
                    </v-card-text>

                    <v-card-text>
                      <b>Validity : </b>{{ answer.result.validity + ' %' }}
                    </v-card-text>
                  </v-col>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { AssessmentAnswersService } from '@/services/assessment-answers-service';
import { getFullPath } from '../../utils/local';

export default {
  data() {
    return {
      dataLoading: true,
      answer: {},
      service: new AssessmentAnswersService()
    };
  },
  methods: {
    getFullPath,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm A');
    },

    async loadAnswer() {
      try {
        this.dataLoading = true;
        this.answer = await this.service.fetchOne(this.$route.query.id);
        if (this.answer.assessmentType == 1) {
          this.answer.assessmentType = 'Behavioral Intelligence';
        } else if (this.answer.assessmentType == 2) {
          this.answer.assessmentType = 'Wheel Of Life';
        } else {
          this.answer.assessmentType = 'Additional Assessment';
        }
      } catch (e) {
        console.log(e);
      }

      this.dataLoading = false;
    }
  },
  async mounted() {
    await this.loadAnswer();
  }
};
</script>
