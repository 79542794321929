import AdminsView from './AdminsView';
import AdminForm from './AdminForm.vue';
import { getUser } from '../../utils/local';

export const superAdminsRoutes = [
  getUser()?.role === 0
    ? {
        name: 'Admins',
        path: '/super-admins',
        component: AdminsView
      }
    : null,

  getUser()?.role === 0
    ? {
        name: 'NewAdmin',
        path: '/super-admin',
        component: AdminForm
      }
    : null
];

export const superAdminsRouter = superAdminsRoutes.filter(function (x) {
  return x !== null;
});
