import UserView from './UserView';
import UserShow from './UserShow';
import UserForm from './UserForm.vue';
import { getUserScopes } from '../../utils/local';
import { getUser } from '../../utils/local';

export const businessUsersRoutes = [
  getUserScopes()?.includes('businessUsers:view') || getUser()?.role === 0
    ? {
        name: 'BusinessUser',
        path: '/business-users',
        component: UserView
      }
    : {
        path: '/business-users',
        redirect: '/no-permission'
      },

  getUserScopes()?.includes('businessUsers:create') || getUser()?.role === 0
    ? {
        name: 'NewBusinessUser',
        path: '/business-user',
        component: UserForm
      }
    : {
        path: '/business-user',
        redirect: '/no-permission'
      },
  getUserScopes()?.includes('businessUsers:view') || getUser()?.role === 0
    ? {
        name: 'BusinessUserDetails',
        path: '/business-user-details',
        component: UserShow
      }
    : {
        path: '/business-user-details',
        redirect: '/no-permission'
      }
];

export const businessUsersRouter = businessUsersRoutes.filter(function (x) {
  return x !== null;
});
