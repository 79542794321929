<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay
        v-if="dataLoading"
        :absolute="dataLoading"
        color="primary"
        opacity="0.55"
      >
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-row no-gutters class="d-flex align-center">
          <v-btn color="primary" outlined @click="$router.back()">
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
          <v-card-title style="color: #020819">User Details</v-card-title>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card-text> <b>Name : </b>{{ user.name }} </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text> <b>Email : </b>{{ user.email }} </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6" v-if="user && user.contact">
            <v-card-text> <b>Contact : </b>{{ user.contact }} </v-card-text>
          </v-col>

          <v-col cols="12" md="6" v-if="user && user.address">
            <v-card-text> <b>Address : </b>{{ user.address }} </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card-text> <b>Premium User : </b>{{ user.isPremium ? 'Premium' : 'Not Premium' }} </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <v-card-text>
              <b>Answers : </b>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                  v-for="(mainAnswer, index) in assessmentAnswers"
                  :key="index"
                  style="
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    margin: 10px 0px;
                  "
                >
                  <div
                    v-for="(answer, index) in mainAnswer.answers"
                    :key="index"
                  >
                    <v-card-text>
                      <b>Question : </b>{{ answer.question.question }}
                    </v-card-text>
                    <v-card-text>
                      <b>Answer : </b>{{ answer.answer }}
                    </v-card-text>
                    <v-card-text>
                      <b>Statement : </b>{{ answer.statement }}
                    </v-card-text>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>

        <v-row class="pa-0" no-gutters>
          <v-col cols="12" md="6">
            <v-card-text>
              <b>Created At : </b>{{ formatDate(user.createdAt) }}
            </v-card-text>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { UsersService } from '../../services/users-service';
import { AssessmentAnswersService } from '@/services/assessment-answers-service';
import { getFullPath } from '../../utils/local';

export default {
  data() {
    return {
      dataLoading: true,
      user: {},
      assessmentAnswers: [],
      service: new UsersService(),
      assessmentAnswersService: new AssessmentAnswersService()
    };
  },
  methods: {
    getFullPath,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm A');
    },

    async loadAgency() {
      try {
        this.dataLoading = true;
        this.user = await this.service.fetchOne(this.$route.query.id);
        this.assessmentAnswers =
          await this.assessmentAnswersService.fetchAllByUserId(
            this.$route.query.id
          );
      } catch (e) {
        console.log(e);
      }
      this.dataLoading = false;
    }
  },
  async mounted() {
    await this.loadAgency();
  }
};
</script>
