import axios from 'axios';

export class StopService {
  async fetchAll() {
    return (await axios.get('/stops')).data;
  }

  async fetchAllByAdminId(id) {
    return (await axios.get('/stops?userId=' + id)).data;
  }

  async addAudioToStop(data, stopId) {
    return (await axios.post('/stop-audios?stopId=' + stopId, data)).data;
  }

  async fetchOne(id) {
    return (await axios.get('/stops/' + id)).data;
  }

  async create(data) {
    return (await axios.post('/stops', data)).data;
  }

  async update(data) {
    return (await axios.patch('/stops/' + data._id, data)).data;
  }

  async delete(id) {
    return (await axios.delete('/stops/' + id)).data;
  }

  async deleteAudioFromStop(id, stopId) {
    return (await axios.delete('stop-audios/' + id + '?stopId=' + stopId)).data;
  }
}
